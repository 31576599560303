import { useState } from 'react';

const cache: Record<string, unknown> = {};

export default function useCache<T>(
  key: string,
  init: (value?: T) => T | undefined = (x?: T): T | undefined => x
): [T | undefined, (value: T) => void] {
  const [state, setState] = useState<T | undefined>(() => init(cache[key] as T | undefined));

  const updateState = (value: T): void => {
    cache[key] = value;
    setState(value);
  };

  return [state, updateState];
}
