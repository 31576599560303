import React, { useState } from 'react';
import styled from 'styled-components';

import { DateTime } from 'luxon';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarIcon from '@mui/icons-material/Star';

import { KUADRO_GREEN } from '../constants/styles';
import { priceToShow } from '../utils/format';
import { getMaxPrice, getFullPrice, getTotalPrice } from '../utils/price';

import { Plan, Coupon } from '../types';

import { Loader } from './Button';

const PlanType = styled.div`
  color: #737373;
  font-weight: bold;
  font-size: 0.9em;
  line-height: 1.3rem;
  margin: 10px;
`;

interface InputDivProps {
  color: string;
}
const InputDiv = styled.div<InputDivProps>`
  width: 100%;
  max-height: 40px;
  border: ${(props): string => `1px solid${props.color};`}
  border-radius: 4px;
  display: flex;
  margin: 0 5px;
  padding: 5px;
`;

interface CouponLabelProps {
  isValid?: boolean;
}

const CouponLabel = styled.div<CouponLabelProps>`
  font-size: 0.9em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  color: ${(props): string => (props.isValid ? KUADRO_GREEN : '#cc4e4a')};
  width: min-content;
  padding: 2px;
  margin: ${(props): string => (props.isValid ? '8px auto 0;' : '8px 10px 0 auto;')};
`;

const Hr = styled.hr`
  width: 80%;
  margin: 10px auto;
  border: 1px solid #c3c3c3;
`;

const PurchaseDetails = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  font-size: 0.8em;
  margin-top: 5px;
`;

const CouponsRemaining = styled.div`
  display: flex;
  background-color: #ff6364;
  border-radius: 4px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  max-width: 260px;
  margin: 40px auto 20px;
`;

interface ButtonProps {
  isAdd?: boolean;
}

const Button = styled.button<ButtonProps>`
  background-color: ${(props): string => (props.isAdd ? '#ccc' : '#0ac17f')};
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 20px;
  font-weight: 600;
  text-transform: none;
  font-size: 14px;
  margin-left: 50px;
  color: #fff;
  line-height: 1;
  border-radius: 5px;
  border: 0px none;
  :disabled {
    cursor: default;
    background: rgb(221, 221, 221);
  }
  margin: auto;
  margin-right: 0;
`;

const MontlhyPayment = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 0.9em;
  padding-top: 10px;
  width: 100%;
  margin: 10px;
`;

const ResumeText = styled.div`
  color: #656565;
  font-size: 16px;
  justify-content: space-between;
  width: 100%;
  display: flex;
  margin: 0 10px;
`;

const SpanPurchase = styled.span`
  margin-right: auto;
`;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      minWidth: 320,
      marginTop: 80,
      flexWrap: 'wrap',
      height: 'min-content',
      padding: '5px 5px 15px',
    },
    desktop: {
      display: 'flex',
      maxWidth: 370,
      minWidth: 320,
      marginTop: 80,
      flexWrap: 'wrap',
      height: 'min-content',
      position: 'fixed',
      right: 120,
    },
    paperCoupon: {
      display: 'flex',
      maxWidth: 480,
      minWidth: 320,
      maxHeight: 460,
      padding: '5px 5px 15px',
    },
    paperCount: {
      display: 'flex',
      maxWidth: 370,
      minWidth: 320,
      marginTop: 30,
      padding: '25px 15px',
      fontSize: '18px',
      justifyContent: 'space-between',
    },
    paperCountDesktop: {
      display: 'flex',
      maxWidth: 370,
      minWidth: 340,
      marginTop: 30,
      padding: '25px 15px',
      fontSize: '18px',
      justifyContent: 'space-between',
    },
  })
);

const GridFlex = withStyles({
  root: {
    display: 'flex',
  },
})(Grid);

const TypographyTitle = withStyles({
  root: {
    width: 160,
    fontWeight: 700,
    fontSize: '18px',
    marginTop: 10,
  },
})(Typography);

const FavoriteIcon = withStyles({
  root: {
    fontSize: 50,
    margin: 'auto',
    color: '#D74747',
  },
})(FavoriteBorderIcon);

const DISABLE_RATINGS = true;

const Rating: React.FC<{ rating: number }> = ({ rating }) => {
  if (DISABLE_RATINGS) return null;
  const fullStars = Math.floor(rating + 0.2);
  const emptyStars = Math.floor(5 - rating + 0.2);
  const halfStars = 5 - fullStars - emptyStars;

  return (
    <Grid container alignItems="center">
      {new Array(fullStars)
        .fill(0)
        .map((a, i) => 10 + i)
        .map(a => (
          <Grid item key={a}>
            <StarIcon style={{ color: '#FFDF00' }} />
          </Grid>
        ))}
      {new Array(halfStars)
        .fill(0)
        .map((a, i) => 20 + i)
        .map(a => (
          <Grid item key={a}>
            <StarHalfIcon style={{ color: '#FFDF00' }} />
          </Grid>
        ))}
      {new Array(emptyStars)
        .fill(0)
        .map((a, i) => 30 + i)
        .map(a => (
          <Grid item key={a}>
            <StarBorderIcon style={{ color: '#FFDF00' }} />
          </Grid>
        ))}
      <Grid item>
        <strong>{rating.toFixed(1)}</strong>
      </Grid>
    </Grid>
  );
};

const InsertCupon: React.FC<TitleProps> = ({ coupon, applyCoupon }) => {
  const [couponInput, setCouponInput] = useState<string>(coupon ? coupon.alias : '');
  const [error, setError] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const applyDiscount = (): Promise<void> => applyCoupon(couponInput).then(setError);

  const showAddButton = !error && (!coupon || coupon.alias !== couponInput);

  let color: string;
  if (error === null) color = '#ccc';
  else color = coupon ? KUADRO_GREEN : '#ff0000';
  return (
    <div>
      <div style={{ display: 'flex', margin: '30px 0 5px' }}>
        <InputDiv color={color}>
          <InputBase
            key="input_name"
            onChange={(event): void => {
              setCouponInput(event.target.value);
              setError(null);
            }}
            value={couponInput}
            placeholder="Cupom de Desconto"
            inputProps={{ 'aria-label': 'naked' }}
          />
          {error !== null && <div>{!!coupon && <CouponLabel isValid>CUPOM ADICIONADO</CouponLabel>}</div>}
          {showAddButton && (
            <Button
              onClick={(): void => {
                setLoading(true);
                applyDiscount().finally(() => setLoading(false));
              }}
              disabled={!couponInput}
            >
              {loading ? <Loader /> : 'Aplicar'}
            </Button>
          )}
        </InputDiv>
      </div>
      {error !== null && <div>{!!coupon || <CouponLabel>Cupom Inválido</CouponLabel>}</div>}
    </div>
  );
};

const FullTotalToPay: React.FC<TitleProps> = ({ coupon, plan }) => {
  const maxPrice = getMaxPrice(plan);
  const fullPrice = getFullPrice(plan, coupon);
  const totalPrice = getTotalPrice(plan, coupon);

  return (
    <div style={{ width: '100%', marginTop: 10 }}>
      <PurchaseDetails>
        <ResumeText style={{ color: '#aaaaaa' }}>
          <SpanPurchase>De: </SpanPurchase>
          <s>R$ {priceToShow(maxPrice)}</s>
        </ResumeText>
      </PurchaseDetails>
      <PurchaseDetails>
        <ResumeText>
          <SpanPurchase>Por: </SpanPurchase>
          <span style={{ color: '#008000', fontWeight: 700 }}>R$ {priceToShow(fullPrice)}</span>
        </ResumeText>
      </PurchaseDetails>
      <PurchaseDetails>
        <ResumeText>
          <SpanPurchase>Ou em até {plan.maxInstallments}x</SpanPurchase>
          <span>R$ {priceToShow(totalPrice / plan.maxInstallments)}</span>
        </ResumeText>
      </PurchaseDetails>
      <Hr />
      {maxPrice - fullPrice > 0 && (
        <PurchaseDetails>
          <ResumeText style={{ color: '#ff0000' }}>
            <SpanPurchase>Economia total à vista: </SpanPurchase>
            <span>R$ {priceToShow(maxPrice - fullPrice)}</span>
          </ResumeText>
        </PurchaseDetails>
      )}
      {maxPrice - totalPrice > 0 && (
        <PurchaseDetails>
          <ResumeText style={{ color: '#ff0000' }}>
            <SpanPurchase>Economia total parcelado: </SpanPurchase>
            <span>R$ {priceToShow(maxPrice - totalPrice)}</span>
          </ResumeText>
        </PurchaseDetails>
      )}
      {coupon && (coupon.limited || coupon.until) && (
        <CouponsRemaining>
          <AccessTimeIcon style={{ fontSize: 14 }} />
          <div style={{ marginLeft: 10 }}>
            {coupon.limited ? (
              <span>
                Restam {coupon.remaining} cupons
                <span> {` ${coupon.alias.toUpperCase()}`}</span>
              </span>
            ) : (
              <span>
                O desconto expira
                <span> {DateTime.fromISO(coupon.until).toRelative({ locale: 'pt-br' })}</span>
              </span>
            )}
          </div>
        </CouponsRemaining>
      )}
    </div>
  );
};

const MonthlyTotalToPay: React.FC<TitleProps> = ({ plan }) => (
  <MontlhyPayment>
    <div>Valor por mês</div>
    <div>
      R$
      {priceToShow(plan.price)}
    </div>
  </MontlhyPayment>
);

interface TitleProps {
  plan: Plan;
  coupon?: Coupon;
  applyCoupon: (s: string) => Promise<boolean>;
}

const Title: React.FC<TitleProps> = ({ plan, applyCoupon, coupon }) => {
  const classes = useStyles();
  const isPhoneDevice = useMediaQuery('(max-width:1100px)');
  if (plan.type !== 'MONTHLY') {
    return (
      <div className={isPhoneDevice ? classes.root : classes.desktop}>
        <Paper className={classes.paperCoupon}>
          <GridFlex container>
            <GridFlex item xs={12}>
              {!!plan.imageUrl && <img alt="" src={plan.imageUrl} width="129px" height="71px" style={{ margin: 10 }} />}
              <TypographyTitle>{plan.name}</TypographyTitle>
            </GridFlex>
            {!!plan.rating && (
              <Grid key={1} item xs={12} style={{ margin: '30px 10px 0' }}>
                <Rating rating={plan.rating} />
              </Grid>
            )}
            <GridFlex item xs={12} style={{ display: 'block' }}>
              {plan.enableDiscount && <InsertCupon coupon={coupon} plan={plan} applyCoupon={applyCoupon} />}
            </GridFlex>
            <FullTotalToPay plan={plan} coupon={coupon} applyCoupon={applyCoupon} />
          </GridFlex>
        </Paper>
        {plan.count > 0 && (
          <Paper className={isPhoneDevice ? classes.paperCount : classes.paperCountDesktop}>
            <div style={{ display: 'flex', width: 'min-content' }}>
              <FavoriteIcon />
            </div>
            <div style={{ width: '250px', margin: '4px 0' }}>
              <span>
                <strong>{plan.count + 7}</strong> estudantes se matricularam nesta turma na última semana
              </span>
            </div>
          </Paper>
        )}
      </div>
    );
  }

  return (
    <div className={isPhoneDevice ? classes.root : classes.desktop}>
      <Paper className={classes.paperCoupon}>
        <GridFlex container>
          <GridFlex item xs={12}>
            {!!plan.imageUrl && <img alt="" src={plan.imageUrl} width="129px" height="71px" style={{ margin: 10 }} />}
            <TypographyTitle>{plan.name}</TypographyTitle>
          </GridFlex>
          {!!plan.rating && (
            <Grid key={1} item xs={12} style={{ margin: '30px 10px 0' }}>
              <Rating rating={plan.rating} />
            </Grid>
          )}
          <PlanType>Mensal - Renovação Automática</PlanType>
          <MonthlyTotalToPay plan={plan} coupon={coupon} applyCoupon={applyCoupon} />
        </GridFlex>
      </Paper>
      {plan.count > 0 && (
        <Paper className={isPhoneDevice ? classes.paperCount : classes.paperCountDesktop}>
          <div style={{ display: 'flex', width: 'min-content' }}>
            <FavoriteIcon />
          </div>
          <div style={{ width: '250px', margin: '4px 0' }}>
            <span>
              <strong>{plan.count + 7}</strong> estudantes se matricularam nesta turma na última semana
            </span>
          </div>
        </Paper>
      )}
    </div>
  );
};

export default Title;
