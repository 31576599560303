import React from 'react';
import styled from 'styled-components';

import withStyles from '@mui/styles/withStyles';
import MaterialButton from '@mui/material/Button';

import { KUADRO_GREEN } from '../constants/styles';

const Button = styled.button`
  cursor: pointer;
  width: 100%;
  text-transform: uppercase;
  font-weight: 400;
  background-color: rgb(255, 160, 0);
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 1;
  padding: 12px 60px;
  border: 0px none;
  border-radius: 19px;
  :disabled {
    cursor: default;
    background: rgb(221, 221, 221);
  }
`;

const Spinner = styled.div`
  // width: 70px;
  width: 100%;
  text-align: center;

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;

interface BounceProps {
  delay: string;
}

const Bounce = styled.div<BounceProps>`
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 3s infinite ease-in-out both;
  animation: sk-bouncedelay 3s infinite ease-in-out both;
  -webkit-animation-delay: ${(props): string => props.delay};
  animation-delay: ${(props): string => props.delay};
`;

export const Loader: React.FC = () => (
  <Spinner>
    <Bounce key="bounce1" delay="0s" />
    <Bounce key="bounce2" delay="-0.16s" />
    <Bounce key="bounce3" delay="-0.32s" />
  </Spinner>
);

export const SaveButton = withStyles({
  root: {
    fontFamily: 'Roboto',
    backgroundColor: KUADRO_GREEN,
    border: 'none',
    borderRadius: 5,
    fontWeight: 700,
    fontSize: 16,
    color: '#ffffff',
    padding: '6px 18px',
    '&:hover': {
      backgroundColor: '#24864f',
    },
  },
  disabled: {
    backgroundColor: '#8fbc8f',
    border: 'none',
    borderRadius: 5,
    fontWeight: 700,
    fontSize: 16,
    color: '#ffffff !important',
    padding: '6px 18px',
  },
})(MaterialButton);

export const EditButton = withStyles({
  root: {
    fontFamily: 'Roboto',
    border: 'none',
    fontWeight: 400,
    fontSize: 16,
    color: KUADRO_GREEN,
    padding: 0,
    '&:hover': {
      color: '#24864f',
    },
  },
})(MaterialButton);

export default Button;
