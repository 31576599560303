import React from 'react';
import styled from 'styled-components';

import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Loader, SaveButton } from './Button';

const Box = styled.div`
  background-color: #fff;
  display: flex;
  margin: 15px 0px 10px 0px;
  box-shadow: 0px 0px 6px 0px #636262ad;
  border-radius: 5px;
  padding: 15px 20px;
  flex-direction: column;
  font-size: 18px;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

interface BoletoProps {
  onNext: () => void;
  planType: string;
  enable: boolean;
  paying: boolean;
}

const Boleto: React.FC<BoletoProps> = ({ onNext, planType, enable, paying }) => (
  <div style={{ display: 'block' }}>
    <Box>
      <h1>Atente-se aos detalhes:</h1>
      <ul>
        {planType !== 'MONTHLY' && <li>Boleto (somente à vista)</li>}
        <li>
          Pagamentos com Boleto Bancário levam até 3 dias úteis para serem compensados e então terem os produtos
          liberados
        </li>
        <li>
          Atente-se ao vencimento do boleto. Você pode pagar o boleto em qualquer banco ou casa lotérica até o dia do
          vencimento
        </li>
        <li>
          Depois do pagamento, verifique seu e-mail para receber os dados de acesso ao produto (verifique também a caixa
          de SPAM)
        </li>
      </ul>
    </Box>
    <SaveButton disabled={!enable || paying} onClick={onNext}>
      {paying ? (
        <Loader />
      ) : (
        <div style={{ display: 'flex' }}> {!enable ? <LockIcon /> : <LockOpenIcon />} Gerar Boleto</div>
      )}
    </SaveButton>
  </div>
);

export default Boleto;
