import React, { useState } from 'react';

import styled from 'styled-components';

import withStyles from '@mui/styles/withStyles';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { CheckBox, InputStyled } from './Input';
import AddressCMP from './Address';
import { SaveButton, EditButton } from './Button';

import { maskCPF, unmaskCPF, unmaskPhone, maskPhone } from '../utils/format';
import { onAddressNext } from '../utils/marketing';
import { validateAddress, validateCPF, validateEmail, validatePhone, validateString } from '../utils/validators';

import { Customer, Address, Plan, Coupon } from '../types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px;
`;

const CheckboxDiv = styled.div`
  align-items: center;
  color: rgb(84, 110, 122);
  cursor: pointer;
  display: flex;
  font-weight: 300;
  margin: 10px 0;
`;

const Label = styled.div`
  font-size: 16px;
  color: #171717;
  padding: 10px 0px 5px 0px;
  text-transform: uppercase;
  font-weight: 600;
  inline-size: max-content;
`;

const ShowDataGrid = withStyles({
  root: {
    display: 'flex',
  },
})(Grid);

const TitleTypography = withStyles({
  root: {
    width: 'max-content',
    fontSize: 16,
    fontWeight: 700,
    margin: 'auto 5px auto 0',
  },
})(Typography);

interface CustomerProps {
  plan: Plan;
  coupon: Coupon | undefined;
  address: Address;
  customer: Customer;
  enableNext: boolean;
  onChangeAddress: (c: Address) => void;
  onChange: (c: Customer) => void;
  onCheckToU: (c: boolean) => void;
  onNext: () => void;
  checkedToU: boolean;
}

const CustomerCMP: React.FC<CustomerProps> = ({
  plan,
  coupon,
  address,
  customer,
  enableNext,
  onChange,
  onCheckToU,
  onChangeAddress,
  onNext,
  checkedToU,
}) => {
  const [isFinancier, setIsFinancier] = useState(false);
  const [editingFinancier, setEditingFinancier] = useState(true);

  const { financierName, financierEmail, cpf, phone, studentCpf } = customer;
  const maskedCPF = maskCPF(cpf);
  const maskedStudentCPF = maskCPF(studentCpf);
  const maskedPhone = maskPhone(phone);

  const showEmail = (userEmail: string): string => {
    const splits = userEmail.split('@');
    const mailbox = splits[0];
    if (mailbox.length < 2) return userEmail;
    const maskedSplit = mailbox
      .slice(0, 1)
      .concat(new Array(mailbox.length - 2).fill('*').join(''))
      .concat(mailbox.slice(mailbox.length - 1));
    return [maskedSplit, ...splits.slice(1)].join('@');
  };

  const setFinancier = (f: boolean): void => {
    if (f) {
      const newName = customer.name || customer.financierName;
      const newEmail = customer.email || customer.financierEmail;
      const newPhone = customer.studentPhone || customer.phone;
      const newCpf = customer.studentCpf || customer.cpf;
      onChange({
        ...customer,
        cpf: newCpf,
        financierName: newName,
        financierEmail: newEmail,
        phone: newPhone,
      });
    }
    setIsFinancier(f);
  };

  const updateCustomer = (c: Customer): void => {
    if (isFinancier) {
      c.email = c.financierEmail;
      c.name = c.financierName;
      c.studentPhone = c.phone;
      c.studentCpf = c.cpf;
    }
    onChange(c);
  };

  const handleNext = (): void => {
    window.scrollTo(0, 0);
    setEditingFinancier(false);
    if (customer.email && validateAddress(address)) onAddressNext(customer, address, plan, coupon);
    onNext();
  };

  return (
    <Container>
      <CheckboxDiv
        onClick={(): void => {
          setFinancier(!isFinancier);
        }}
      >
        <CheckBox key="input_is_financier" checked={isFinancier} onChange={(): void => {}} />
        <Label>Eu (aluno) também sou responsável pelo pagamento</Label>
      </CheckboxDiv>
      <div>
        {editingFinancier ? (
          <Accordion style={{ boxShadow: 'none' }} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>Dados do responsável financeiro</AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputStyled
                    key="input_financier_name"
                    value={financierName}
                    onChange={(value): void => updateCustomer({ ...customer, financierName: value })}
                    title="Nome completo do responsável"
                    validate={validateString}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputStyled
                    key="input_financier_email"
                    value={financierEmail}
                    onChange={(value): void =>
                      updateCustomer({ ...customer, financierEmail: value && value.toLowerCase().trim() })
                    }
                    type="email"
                    title="Email do responsável"
                    validate={validateEmail}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputStyled
                    type="text"
                    inputMode="numeric"
                    key="input_phone"
                    value={maskedPhone}
                    onChange={(value): void => updateCustomer({ ...customer, phone: unmaskPhone(value) })}
                    title="Celular / Telefone do responsável"
                    validate={(value): boolean => validatePhone(unmaskPhone(value))}
                    required
                  />
                </Grid>
                <Grid item xs={isFinancier ? 12 : 6}>
                  <InputStyled
                    type="text"
                    inputMode="numeric"
                    key="input_cpf"
                    value={maskedCPF}
                    onChange={(value): void => updateCustomer({ ...customer, cpf: unmaskCPF(value) })}
                    title="CPF do responsável"
                    validate={(value): boolean => validateCPF(unmaskCPF(value))}
                    required
                  />
                </Grid>
                {!isFinancier && (
                  <Grid item xs={6}>
                    <InputStyled
                      type="text"
                      inputMode="numeric"
                      key="input_student_cpf"
                      value={maskedStudentCPF}
                      onChange={(value): void => onChange({ ...customer, studentCpf: unmaskCPF(value) })}
                      title="CPF do aluno"
                      validate={(value): boolean => validateCPF(unmaskCPF(value))}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <AddressCMP address={address} onChange={onChangeAddress} />
                </Grid>
                <Grid item xs={12} style={{ paddingLeft: 4 }}>
                  <CheckboxDiv
                    style={{ margin: '10px' }}
                    onClick={(): void => {
                      onCheckToU(!checkedToU);
                    }}
                  >
                    <CheckBox style={{ padding: '0 8px 0 0' }} checked={checkedToU} onChange={(): void => {}} />
                    <div>
                      {`Aceito os `}
                      <a href="https://www.kuadro.com.br/blog/termos-de-uso/" target="_blank" rel="noopener noreferrer">
                        Termos de Uso
                      </a>
                    </div>
                  </CheckboxDiv>
                  <SaveButton style={{ marginLeft: 3 }} disabled={!enableNext} onClick={handleNext}>
                    Salvar
                  </SaveButton>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ) : (
          <Grid container spacing={2} style={{ margin: '0 20px' }}>
            <ShowDataGrid item xs={6}>
              <TitleTypography>Dados do responsável</TitleTypography>
              <EditButton onClick={(): void => setEditingFinancier(true)}>Alterar</EditButton>
            </ShowDataGrid>
            <ShowDataGrid item xs={12}>
              {financierName}
            </ShowDataGrid>
            <ShowDataGrid item xs={12}>
              {showEmail(financierEmail)}
            </ShowDataGrid>
            <ShowDataGrid item xs={12}>
              {address.street}, {address.number}
            </ShowDataGrid>
          </Grid>
        )}
      </div>
    </Container>
  );
};

export default CustomerCMP;
