import React from 'react';
import { Redirect, BrowserRouter, Route, Switch } from 'react-router-dom';

import styled from 'styled-components';

import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material/styles';

import ErrorBoundary from './components/ErrorBoundary';
import Checkout, { CheckoutProps } from './containers/Checkout';
import PayMissing from './containers/PayMissing';
import ChangeCard from './containers/ChangeCard';
import End from './containers/End';

import { KUADRO_THEME } from './constants/styles';

const RootBackground = styled.div`
  align-items: center;
  border: none;
  display: flex;
  font-family: 'Roboto', sans-serif;
  justify-content: center;
  min-height: 100vh;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 100vw;
`;

const RootBackgroundImage = styled.div`
  align-items: center;
  border: none;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
`;

const PayByCheckoutBoletoAndCard: React.FC<CheckoutProps> = ({ match, history, location }) => (
  <Checkout match={match} history={history} location={location} forceBoletoAndCard />
);

const RedirectToIta: React.FC = () => <Redirect to="/turma/turma-ita-ime" />;
const RedirectToMed: React.FC = () => <Redirect to="/turma/turma-medicina" />;

const oldItaPlans = [
  'ita_2020_int_bla_bol',
  'ita_2020_int_pro',
  'ita_2020_int',
  'ita_2020_int_bol',
  'ita_2020_int_car',
  'ita_2020_int_rem2',
  'ita_2020_int_rem3',
  'ita_2020_plat_vista',
  'ita_2020_int_12m_bol',
  'ita_2020_int_bla_bol2',
  'ita_2020_int_bla_bol02',
  'ita_2020_int_12m_bol2',
  'ita_2020_plat_parc_2',
  'ita_2020_plat_vista_mar_1',
  'ita_2020_plat_vista2',
  'ita_2020_plat_parc',
  'ita_2020_int_12m_vista',
  'ita_2020_int_rem4',
  'ita_2020_int_bla_bol002',
  'ita_2020_int_12m',
  'ita_2020_int_full',
  'ita_2020_int_rem',
  'ita_2020_semi_avista_vip',
  'ita_2020_semi_vip',
  'ita-integral-parcelado',
  'ita_2020_plat_vista_2',
  'ita_2020_plat',
  'ita_2020_plat_vista_mar_2',
  'ita_2020_semi_avista',
  'ita_2020_semi',
  'ita_2020_plat_semi_avista',
  'ita_2020_plat_semi',
];

const oldMedPlans = [
  'med_2020_int_bla_bol',
  'med_2020_int_12m_bol',
  'med_2020_int_pro',
  'med_2020_int_car',
  'med_2020_int_bol',
  'med_2020_int_rem2',
  'med_2020_int_rem3',
  'med_2020_int_rem4',
  'med_2020_int_12m_bol2',
  'med_2020_int_rem',
  'med_2020_plat_vista_mar_1',
  'med_2020_plat_vista_mar_2',
  'med_2020_plat_vista_mar_01',
  'med_2020_plat_vista2',
  'med_2020_plat_vista',
  'medicina_2020_plat_parc',
  'med_2020_int_12m_vista',
  'med_2020_int_bla_bol2',
  'med_2020_int_12m',
  'med_2020_int_full',
  'med_2020_int',
  'med_2020_plat_vista_cupom',
  'med_2020_semi__vip',
  'med_2020_semi_avista_vip',
  'med-integral-parcelado',
  'med_2020_plat',
  'med_2020_plat_vista_2',
  'med_2020_plat_parc_2',
  'med_2020_plat_semi_avista',
  'med_2020_plat_semi',
];

const App: React.FC = () => {
  // It will redirect the top page when it's inside an iframe
  if (window !== window.parent && window.top) window.top.location.href = window.location.toString();
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={KUADRO_THEME}>
        <RootBackground>
          <RootBackgroundImage>
            <BrowserRouter>
              <ErrorBoundary>
                <Switch>
                  <Route key="paymissing" exact path="/pagar/:enrollmentId" component={PayMissing} />
                  <Route key="changecard" exact path="/trocar_cartao/:enrollmentId" component={ChangeCard} />
                  <Route key="endpage" exact path="/finalizado/:status/:planCode/:afcCode?" component={End} />
                  <Route
                    key="advisorboletoandcard"
                    exact
                    path="/tutoria/boleto_e_cartao/:planCode/:afcCode?"
                    component={PayByCheckoutBoletoAndCard}
                  />
                  <Route key="advisorgeneral" exact path="/tutoria/:planCode/:afcCode?" component={Checkout} />
                  {oldItaPlans.map(p => (
                    <Route
                      key={`classboletoandcard${p}`}
                      exact
                      path={`/turma/boleto_e_cartao/${p}/:afcCode?`}
                      component={RedirectToIta}
                    />
                  ))}
                  {oldMedPlans.map(p => (
                    <Route
                      key={`classboletoandcard${p}`}
                      exact
                      path={`/turma/boleto_e_cartao/${p}/:afcCode?`}
                      component={RedirectToMed}
                    />
                  ))}
                  <Route
                    key="classboletoandcard"
                    exact
                    path="/turma/boleto_e_cartao/:planCode/:afcCode?"
                    component={PayByCheckoutBoletoAndCard}
                  />
                  {oldItaPlans.map(p => (
                    <Route key="classgeneral{p}" exact path={`/turma/${p}/:afcCode?`} component={RedirectToIta} />
                  ))}
                  {oldMedPlans.map(p => (
                    <Route key="classgeneral{p}" exact path={`/turma/${p}/:afcCode?`} component={RedirectToMed} />
                  ))}
                  <Route key="classgeneral" exact path="/turma/:planCode/:afcCode?" component={Checkout} />
                  {oldItaPlans.map(p => (
                    <Route
                      key={`boletoandcard${p}`}
                      exact
                      path={`/boleto_e_cartao/${p}/:afcCode?`}
                      component={RedirectToIta}
                    />
                  ))}
                  {oldMedPlans.map(p => (
                    <Route
                      key={`boletoandcard${p}`}
                      exact
                      path={`/boleto_e_cartao/${p}/:afcCode?`}
                      component={RedirectToMed}
                    />
                  ))}
                  <Route
                    key="boletoandcard"
                    exact
                    path="/boleto_e_cartao/:planCode/:afcCode?"
                    component={PayByCheckoutBoletoAndCard}
                  />
                  {oldItaPlans.map(p => (
                    <Route key="general{p}" exact path={`/${p}/:afcCode?`} component={RedirectToIta} />
                  ))}
                  {oldMedPlans.map(p => (
                    <Route key="general{p}" exact path={`/${p}/:afcCode?`} component={RedirectToMed} />
                  ))}
                  <Route key="general" exact path="/:planCode/:afcCode?" component={Checkout} />
                  <Route
                    key="advisorboletoandcardstep"
                    exact
                    path="/tutoria/boleto_e_cartao/:planCode/:afcCode/:step?"
                    component={PayByCheckoutBoletoAndCard}
                  />
                  <Route
                    key="advisorgeneralstep"
                    exact
                    path="/tutoria/:planCode/:afcCode/:step?"
                    component={Checkout}
                  />
                  <Route
                    key="classboletoandcardstep"
                    exact
                    path="/turma/boleto_e_cartao/:planCode/:afcCode/:step?"
                    component={PayByCheckoutBoletoAndCard}
                  />
                  <Route
                    key="boletoandcardstep"
                    exact
                    path="/boleto_e_cartao/:planCode/:afcCode/:step?"
                    component={PayByCheckoutBoletoAndCard}
                  />
                  <Route key="classstep" exact path="/turma/:planCode/:afcCode/:step?" component={Checkout} />
                  <Route key="generalstep" exact path="/:planCode/:afcCode/:step?" component={Checkout} />
                  <Route key="default" path="/" component={RedirectToMed} />
                </Switch>
              </ErrorBoundary>
            </BrowserRouter>
          </RootBackgroundImage>
        </RootBackground>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
