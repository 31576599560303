export const priceToShow = (cents: number): string => {
  if (cents < 0) return '0,00';
  const price = cents / 100;
  return price.toFixed(2).replace('.', ',');
};

export default { priceToShow };

export const maskPrice = (price: number): string => {
  const aux = Math.floor(+price / 100);
  if (aux < 0) return '0';
  return `${aux}`;
};

export const unmaskPrice = (price: string): number => 100 * +price.replace(/[^0-9]/g, '').slice(0, 8);

export const maskCardNumber = (number: string): string => {
  if (!number) return '';
  if (number.length < 5) return number;
  let masked = '';
  for (let i = 0; i < number.length; i += 4) {
    masked += ` ${number.substring(i, i + 4)}`;
  }
  return masked.trim();
};

export const unmaskCardNumber = (number: string): string => {
  if (!number) return '';
  return number.replace(/[^0-9]/gi, '').substring(0, 19);
};

export const maskCardExpirationDate = (number: string): string => {
  if (!number) return '';
  if (number.length < 3) return number;
  return `${number.substring(0, 2)}/${number.substring(2, 4)}`;
};

export const unmaskCardExpirationDate = (number: string): string => {
  if (!number) return '';
  return number.replace(/[^0-9]/gi, '').substring(0, 4);
};

export const maskPhone = (phone: string): string => {
  if (!phone) return '';
  let masked = '(';
  masked += phone.substring(0, 2);
  if (phone.length > 2) masked += ') ';
  if (phone.length < 7) masked += phone.substring(2, 6);
  else if (phone.length < 11) masked += `${phone.substring(2, 6)} - ${phone.substring(6, 10)}`;
  else masked += `${phone.substring(2, 7)} - ${phone.substring(7, 11)}`;
  return masked;
};

export const unmaskPhone = (phone: string): string => {
  if (!phone) return '';
  return phone.replace(/[^0-9]/gi, '').substring(0, 11);
};

export const maskCPF = (cpf: string): string => {
  if (!cpf) return '';
  if (cpf.length < 4) return cpf;
  let masked = `${cpf.substring(0, 3)}.${cpf.substring(3, 6)}`;
  if (cpf.length > 6) {
    masked += `.${cpf.substring(6, 9)}`;
  }
  if (cpf.length > 9) {
    masked += `-${cpf.substring(9, 11)}`;
  }
  return masked;
};

export const unmaskCPF = (cpf: string): string => {
  if (!cpf) return '';
  return cpf.replace(/[^0-9]/gi, '').substring(0, 11);
};
