/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

import * as serviceWorker from './serviceWorker';
import { PROD } from './env';
import './index.css';

if (PROD) {
  disableReactDevTools();
}

if (!('fromEntries' in Object))
  Object.fromEntries = <K extends string | number | symbol, V extends any>(arr: [K, V][]): Record<K, V> =>
    arr.reduce((acc, cur) => {
      const [key, value] = cur;
      acc[key] = value;
      return acc;
    }, {} as Record<K, V>);

if (!('flat' in Array.prototype))
  Object.defineProperty(Array.prototype, 'flat', {
    value: function flatPolyfill(depth = 1) {
      return this.reduce(function (flat: any[], toFlatten: any[]) {
        return flat.concat(Array.isArray(toFlatten) && depth > 1 ? toFlatten.flat(depth - 1) : toFlatten);
      }, []);
    },
  });

// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, 'includes', {
    value: function includesPolyfill(searchElement: any, fromIndex?: number) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      const o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      const len = o.length >>> 0;

      // 3. If len is 0, return false.
      if (len === 0) {
        return false;
      }

      // 4. Let n be ? ToInteger(fromIndex).
      //    (If fromIndex is undefined, this step produces the value 0.)
      const n = fromIndex || 0;

      // 5. If n ≥ 0, then
      //  a. Let k be n.
      // 6. Else n < 0,
      //  a. Let k be len + n.
      //  b. If k < 0, let k be 0.
      let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

      // 7. Repeat, while k < len
      while (k < len) {
        // a. Let elementK be the result of ? Get(O, ! ToString(k)).
        // b. If SameValueZero(searchElement, elementK) is true, return true.
        // c. Increase k by 1.
        // NOTE: === provides the correct "SameValueZero" comparison needed here.
        if (o[k] === searchElement) {
          return true;
        }
        k++;
      }

      // 8. Return false
      return false;
    },
  });
}

if (!('finally' in Promise.prototype))
  Object.defineProperty(Promise.prototype, 'finally', {
    value: function finallyPolyfill(callback: () => void) {
      const { constructor } = this;
      return this.then(
        function (value: any) {
          return constructor.resolve(callback()).then(function () {
            return value;
          });
        },
        function (reason: Error) {
          return constructor.resolve(callback()).then(function () {
            throw reason;
          });
        }
      );
    },
  });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
