import React from 'react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { serializeError } from 'serialize-error';

import { CDNImage } from './Image';
import { sendProblemReport } from '../api';
import { makeCheckoutCDNURL } from '../images-cdn';

const ImageBackground = styled.div`
  background-color: #2a9a5b;
  background-image: url(${makeCheckoutCDNURL('checkout-background-1920.png')});
  background-position-x: center;
  display: flex;
  height: 100vh;
  width: 100vw;
  @media (min-width: 600px) {
    background-position-x: right;
  }
`;

const Holder = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0;
  max-width: 1220px;
  @media (min-width: 600px) {
    margin: auto;
  }
`;

const NewHeader = styled.div`
  height: min-content;
  margin-left: 20px;
  margin-top: 20px;
  max-width: 550px;
  width: 100%;
  @media (min-width: 600px) {
    margin-left: 60px;
  }
`;

// eslint-disable-next-line
interface ErrorBoundaryProps {}

interface ErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    const serializedError = serializeError(error);
    // Display fallback UI
    if (!serializedError.stack?.includes('removeChild')) this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    window.console.error('Fatal Error: ', error, info);
    sendProblemReport(
      JSON.stringify({
        url: window.location.href,
        userAgent: window.navigator.userAgent,
        platform: window.navigator.platform,
        language: window.navigator.language,
        error: serializedError,
      })
    );
  }

  render(): React.ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      Swal.fire({
        icon: 'error',
        title: 'Oops! Algo deu errado',
        text:
          'Atualize a pagina e tente novamente ou entre em contato conosco.' +
          ' Caso tenha finalizado a compra, confire sua caixa de entrada por emails de Kuadro Checkout',
        confirmButtonText: 'Ok',
      });

      // You can render any custom fallback UI
      return (
        <ImageBackground>
          <Holder>
            <NewHeader>
              <CDNImage src="logokuadro.svg" width="60px" />
            </NewHeader>
          </Holder>
        </ImageBackground>
      );
    }

    return children;
  }
}
