import React from 'react';
import styled from 'styled-components';

import { KUADRO_GREEN } from '../constants/styles';

interface ContainerProps {
  margin: string;
  width: string;
}
const Container = styled.div.attrs<ContainerProps>(props => ({
  style: {
    margin: props.margin,
    width: props.width,
  },
}))<ContainerProps>`
  display: inline-table;
  font-size: 0.875rem;
  @media (max-width: 800px) {
    font-size: 0.75rem;
  }
`;

interface DisclaimerProps {
  margin?: string;
  width?: string;
  linkColor?: string;
  linkTextDecoration?: string;
  isPhoneVerification?: boolean;
}

const Disclaimer: React.FC<DisclaimerProps> = ({
  margin = '0px',
  width = '100%',
  linkColor = KUADRO_GREEN,
  linkTextDecoration = 'underline',
  isPhoneVerification = false,
}) => (
  <Container margin={margin} width={width}>
    De acordo com as Leis 12.965/2014 e 13.709/2018, que regulam o uso da Internet e o tratamento de dados pessoais no
    Brasil, ao informar estes dados e seguir para a próxima etapa, eu autorizo o Kuadro a enviar notificações por
    <span>{isPhoneVerification ? ' SMS ' : ' e-mail '}</span>
    ou outros meios e concordo com sua
    <span> </span>
    <a
      style={{ color: linkColor, textDecoration: linkTextDecoration }}
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.kuadro.com.br/politica-de-privacidade/"
    >
      Política de Privacidade.
    </a>
  </Container>
);

export default Disclaimer;
