import React from 'react';
import styled from 'styled-components';

import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Loader, SaveButton } from './Button';

const Box = styled.div`
  background-color: #fff;
  display: flex;
  margin: 15px 0px 10px 0px;
  box-shadow: 0px 0px 6px 0px #636262ad;
  border-radius: 5px;
  padding: 15px 20px;
  flex-direction: column;
  font-size: 18px;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

interface PixProps {
  onNext: () => void;
  enable: boolean;
  paying: boolean;
}

const Pix: React.FC<PixProps> = ({ onNext, enable, paying }) => (
  <div style={{ display: 'block' }}>
    <Box>
      <h1>Atente-se aos detalhes:</h1>
      <ul>
        <li>Pagamentos com Pix são confirmados na hora.</li>
        <li>
          Depois do pagamento, verifique seu e-mail para receber os dados de acesso ao produto (verifique também a caixa
          de SPAM)
        </li>
      </ul>
    </Box>
    <SaveButton disabled={!enable || paying} onClick={onNext}>
      {paying ? (
        <Loader />
      ) : (
        <div style={{ display: 'flex' }}> {!enable ? <LockIcon /> : <LockOpenIcon />} Gerar Pix</div>
      )}
    </SaveButton>
  </div>
);

export default Pix;
