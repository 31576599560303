import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import RadioButton from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Loader, SaveButton } from './Button';
import Boleto from './Boleto';
import Card from './Card';
import Pix from './Pix';

import { KUADRO_GREEN } from '../constants/styles';
import { getFullPrice } from '../utils/price';

import { Plan, Coupon, PaymentInfo, PaymentMethod } from '../types';

import { onPaymentMethodChosen } from '../utils/marketing';

const Radio = withStyles({
  root: {
    '&$checked': {
      color: '#24864f',
    },
  },
  checked: {},
})(RadioButton);

interface AccordionProps {
  onChangeCard: (p: PaymentInfo) => void;
  onChange: (p: PaymentMethod) => void;
  paymentMethod: PaymentMethod;
  enable: boolean;
  paying: boolean;
  pay: () => void;
  plan: Plan;
  enableNext: boolean;
  onChangeSecondCardValue?: (v: number) => void;
  coupon?: Coupon;
  payment: PaymentInfo;
}
const PaymentAccordion: React.FC<AccordionProps> = ({
  onChangeCard,
  onChange,
  paymentMethod,
  enable,
  paying,
  pay,
  plan,
  enableNext,
  onChangeSecondCardValue,
  coupon,
  payment,
}) => (
  <RadioGroup aria-label="payment" name="payment" value={paymentMethod}>
    {plan.payByPix && (!coupon || coupon.payByPix) && (
      <Accordion
        style={{ boxShadow: 'none' }}
        expanded={paymentMethod === PaymentMethod.PIX}
        onChange={(): void => onChange(PaymentMethod.PIX)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <FormControlLabel value={PaymentMethod.PIX} control={<Radio />} label=" Pagar com PIX" />
        </AccordionSummary>
        <AccordionDetails>
          {paymentMethod === PaymentMethod.PIX && <Pix enable={enable} onNext={pay} paying={paying} />}
        </AccordionDetails>
      </Accordion>
    )}
    {plan.payByBoleto && (!coupon || coupon.payByBoleto) && (
      <Accordion
        style={{ boxShadow: 'none' }}
        expanded={paymentMethod === PaymentMethod.BOLETO}
        onChange={(): void => onChange(PaymentMethod.BOLETO)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <FormControlLabel value={PaymentMethod.BOLETO} control={<Radio />} label=" Pagar com boleto" />
        </AccordionSummary>
        <AccordionDetails>
          {paymentMethod === PaymentMethod.BOLETO && (
            <Boleto enable={enable} onNext={pay} planType={plan.type} paying={paying} />
          )}
        </AccordionDetails>
      </Accordion>
    )}
    {plan.payByCard && (!coupon || coupon.payByCard) && (
      <Accordion
        style={{ boxShadow: 'none' }}
        expanded={paymentMethod === PaymentMethod.CARD}
        onChange={(): void => onChange(PaymentMethod.CARD)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <FormControlLabel value={PaymentMethod.CARD} control={<Radio />} label="Pagar com cartão" />
        </AccordionSummary>
        <AccordionDetails>
          {paymentMethod === PaymentMethod.CARD && (
            <Card
              payment={payment}
              paying={paying}
              plan={plan}
              coupon={coupon}
              enableNext={enableNext}
              onNext={pay}
              onChangeCard={(cardToChange): void => onChangeCard({ ...payment, cards: [cardToChange] })}
            />
          )}
        </AccordionDetails>
      </Accordion>
    )}
    {plan.payByTwoCards && (!coupon || coupon.payByTwoCards) && (
      <Accordion
        style={{ boxShadow: 'none' }}
        expanded={paymentMethod === PaymentMethod.TWO_CARDS}
        onChange={(): void => onChange(PaymentMethod.TWO_CARDS)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <FormControlLabel value={PaymentMethod.TWO_CARDS} control={<Radio />} label="Pagar com 2 cartões" />
        </AccordionSummary>
        <AccordionDetails>
          {paymentMethod === PaymentMethod.TWO_CARDS && (
            <div>
              <Card
                payment={payment}
                paying={paying}
                plan={plan}
                coupon={coupon}
                enableNext={enableNext}
                index={0}
                onChangeCard={(cardToChange): void =>
                  onChangeCard({ ...payment, cards: [cardToChange, payment.cards[1]] })
                }
              />
              <hr style={{ color: KUADRO_GREEN }} />
              <Card
                payment={payment}
                paying={paying}
                plan={plan}
                coupon={coupon}
                enableNext={enableNext}
                index={1}
                onChangeCard={(cardToChange): void =>
                  onChangeCard({ ...payment, cards: [payment.cards[0], cardToChange] })
                }
                onChangeSecondCardValue={onChangeSecondCardValue}
              />
              <br />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SaveButton disabled={!enableNext || paying} onClick={pay}>
                    {paying ? <Loader /> : 'Comprar Agora'}
                  </SaveButton>
                </Grid>
              </Grid>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    )}
  </RadioGroup>
);

interface PaymentMethodProps {
  plan: Plan;
  enable: boolean;
  coupon?: Coupon;
  payment: PaymentInfo;
  onChange: (p: PaymentInfo) => void;
  paying: boolean;
  pay: () => void;
  enableNext: boolean;
  onChangeSecondCardValue?: (v: number) => void;
}

const PaymentMethodCMP: React.FC<PaymentMethodProps> = ({
  coupon,
  plan,
  enable,
  enableNext,
  payment,
  onChange,
  paying,
  pay,
  onChangeSecondCardValue,
}) => {
  if (payment.method === PaymentMethod.BOLETO_AND_CARD) return <div />;

  const setPix = (): void => onChange({ method: PaymentMethod.PIX, cards: [] });
  const setBoleto = (): void => onChange({ method: PaymentMethod.BOLETO, cards: [] });

  const setCard = (): void => {
    const fullValue = getFullPrice(plan, coupon);
    const p = {
      method: PaymentMethod.CARD,
      cards: [
        {
          name: '',
          number: '',
          cvv: '',
          expirationDate: '',
          installments: 1,
          value: fullValue,
        },
      ],
    };
    if (payment.cards[0]) {
      const [card] = payment.cards;
      p.cards[0] = { ...card, value: fullValue };
    }
    onChange(p);
  };

  const setTwoCards = (): void => {
    const fullValue = getFullPrice(plan, coupon);
    const p = {
      method: PaymentMethod.TWO_CARDS,
      cards: [
        {
          name: '',
          number: '',
          cvv: '',
          expirationDate: '',
          installments: 1,
          value: fullValue / 2,
        },
      ],
    };
    if (payment.cards[0]) {
      const [card] = payment.cards;
      p.cards[0] = { ...card, installments: 1, value: fullValue / 2 };
    }

    p.cards.push({
      name: '',
      number: '',
      cvv: '',
      expirationDate: '',
      installments: 1,
      value: fullValue - fullValue / 2,
    });

    onChange(p);
  };

  const change = (method: PaymentMethod): void => {
    onPaymentMethodChosen();
    switch (method) {
      case PaymentMethod.PIX:
        setPix();
        break;
      case PaymentMethod.BOLETO:
        setBoleto();
        break;
      case PaymentMethod.CARD:
        setCard();
        break;
      case PaymentMethod.TWO_CARDS:
        setTwoCards();
        break;
      default:
    }
  };

  return (
    <PaymentAccordion
      onChangeCard={onChange}
      onChange={change}
      paymentMethod={payment.method}
      pay={pay}
      paying={paying}
      plan={plan}
      enable={enable}
      enableNext={enableNext}
      onChangeSecondCardValue={onChangeSecondCardValue}
      coupon={coupon}
      payment={payment}
    />
  );
};

export default PaymentMethodCMP;
