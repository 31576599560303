import React from 'react';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

import { Loader, SaveButton } from './Button';
import { InputValidateOnChange, InputStyled } from './Input';
import { Option } from './Select';
import {
  maskPrice,
  unmaskPrice,
  maskCardNumber,
  unmaskCardNumber,
  maskCardExpirationDate,
  unmaskCardExpirationDate,
  priceToShow,
} from '../utils/format';
import { validateString, validateCardNumber, validateCardCVV, validateCardExpirationDate } from '../utils/validators';
import { getFullPrice, getTotalPrice } from '../utils/price';

import { PaymentInfo, Plan, Coupon, PaymentMethod, CardInfo } from '../types';

const useStyles = makeStyles(theme =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

const getInstallmentText = (installment: number, price: number, discount?: number): string =>
  discount
    ? `${installment}x de R$ ${priceToShow(price)} (${discount}% de desconto)`
    : `${installment}x de R$ ${priceToShow(price)} sem juros`;

const getValueOnSecondCard = (payment: PaymentInfo, plan: Plan, coupon?: Coupon): number => {
  const {
    cards: [{ value, installments: installmentsOnFirst }, { installments: installmentsOnSecond }],
  } = payment;
  const fullPrice = getFullPrice(plan, coupon);
  const totalPrice = getTotalPrice(plan, coupon);
  if (installmentsOnFirst === 1 && installmentsOnSecond === 1) return fullPrice - value;
  return totalPrice - value;
};

const getInstallmentOptions = (
  plan: Plan,
  coupon: Coupon | undefined,
  payment: PaymentInfo,
  index?: number,
  withoutFullDiscount?: boolean
): Option[] => {
  if (plan.type === 'MONTHLY') return [];
  if (payment.method === PaymentMethod.CARD) {
    const fullPrice = getFullPrice(plan, coupon, withoutFullDiscount);
    const totalPrice = getTotalPrice(plan, coupon);
    const discount = Math.floor(((totalPrice - fullPrice) / totalPrice) * 100);
    return [{ value: '1', text: getInstallmentText(1, fullPrice, discount), hidden: false }].concat(
      new Array(plan.maxInstallments - 1)
        .fill(1)
        .map((a, i) => i + 2)
        .map(a => ({
          value: `${a}`,
          text: getInstallmentText(a, totalPrice / a),
          hidden: false,
        }))
    );
  }
  if (payment.method === PaymentMethod.BOLETO_AND_CARD) {
    const {
      cards: [{ value }],
    } = payment;
    const fullPrice = getFullPrice(plan, coupon);
    const totalPrice = getTotalPrice(plan, coupon);
    const discount = Math.floor(((totalPrice - fullPrice) / totalPrice) * 100);
    const valueToShow = value > 0 ? value : 0;
    return [{ value: '1', text: getInstallmentText(1, valueToShow, discount), hidden: false }].concat(
      new Array(plan.maxInstallments - 1)
        .fill(1)
        .map((a, i) => i + 2)
        .map(a => ({
          value: `${a}`,
          text: getInstallmentText(a, valueToShow / a),
          hidden: false,
        }))
    );
  }
  if (payment.method === PaymentMethod.TWO_CARDS) {
    const {
      cards: [{ value, installments: installmentsOnFirst }, { installments: installmentsOnSecond }],
    } = payment;
    const fullPrice = getFullPrice(plan, coupon);
    const totalPrice = getTotalPrice(plan, coupon);
    const discount = Math.floor(((totalPrice - fullPrice) / totalPrice) * 100);
    if (index === 0) {
      const valueToShow = value > 0 ? value : 0;
      const showDiscount = installmentsOnSecond === 1;
      return [
        { value: '1', text: getInstallmentText(1, valueToShow, showDiscount ? discount : undefined), hidden: false },
      ].concat(
        new Array(plan.maxInstallments - 1)
          .fill(1)
          .map((a, i) => i + 2)
          .map(a => ({
            value: `${a}`,
            text: getInstallmentText(a, valueToShow / a),
            hidden: false,
          }))
      );
    }
    if (installmentsOnFirst !== 1) {
      const valueToShow = getValueOnSecondCard(payment, plan, coupon);
      return [{ value: '1', text: getInstallmentText(1, valueToShow), hidden: false }].concat(
        new Array(plan.maxInstallments - 1)
          .fill(1)
          .map((a, i) => i + 2)
          .map(a => ({
            value: `${a}`,
            text: getInstallmentText(a, valueToShow / a),
            hidden: false,
          }))
      );
    }
    return [{ value: '1', text: getInstallmentText(1, fullPrice - value, discount), hidden: false }].concat(
      new Array(plan.maxInstallments - 1)
        .fill(1)
        .map((a, i) => i + 2)
        .map(a => ({
          value: `${a}`,
          text: getInstallmentText(a, (totalPrice - value) / a),
          hidden: false,
        }))
    );
  }
  return [];
};

interface CardProps {
  payment: PaymentInfo;
  paying: boolean;
  plan: Plan;
  coupon?: Coupon;
  index?: number;
  enableNext: boolean;
  withoutFullDiscount?: boolean;
  onNext?: () => void;
  onChangeCard: (c: CardInfo) => void;
  onChangeSecondCardValue?: (v: number) => void;
}

const Card: React.FC<CardProps> = ({
  index,
  payment,
  plan,
  coupon,
  paying,
  enableNext,
  withoutFullDiscount,
  onNext,
  onChangeCard,
  onChangeSecondCardValue,
}) => {
  const { method } = payment;
  const card = payment.cards[index || 0];
  const { name, number, cvv, expirationDate, installments, value } = card;
  const maskedCardNumber = maskCardNumber(number);
  const maskedCardExpirationDate = maskCardExpirationDate(expirationDate);
  const installmentsOptions = getInstallmentOptions(plan, coupon, payment, index, withoutFullDiscount);
  const valueOnThisCard = index === 1 ? getValueOnSecondCard(payment, plan, coupon) : value;
  const classes = useStyles();

  const changeValue = (v: number): void => {
    if (index === 1) {
      if (onChangeSecondCardValue) onChangeSecondCardValue(v);
    } else {
      onChangeCard({ ...card, value: v });
    }
  };
  return (
    <Grid container spacing={2}>
      {typeof index === 'number' && (
        <Grid item xs={12}>
          <span style={{ fontWeight: 700 }}>Cartão {` ${index + 1}`}</span>
        </Grid>
      )}
      <Grid item xs={8}>
        <InputStyled
          key="input_card_holder_name"
          text="Digite o nome como impresso no cartão"
          title="Nome do Cartão"
          value={name}
          onChange={(v): void => onChangeCard({ ...card, name: v })}
          validate={validateString}
          required
        />
      </Grid>
      <Grid item xs={4}>
        <InputStyled
          inputMode="numeric"
          text="MM/AA"
          key="input_expirationDate"
          title="Validade"
          value={maskedCardExpirationDate}
          onChange={(v): void => onChangeCard({ ...card, expirationDate: unmaskCardExpirationDate(v) })}
          validate={(v): boolean => validateCardExpirationDate(unmaskCardExpirationDate(v))}
          required
        />
      </Grid>
      <Grid item xs={8}>
        <InputStyled
          type="text"
          inputMode="numeric"
          key="input_card_number"
          text="Digite somente números"
          value={maskedCardNumber}
          onChange={(v): void => onChangeCard({ ...card, number: unmaskCardNumber(v) })}
          title="Número do cartão"
          validate={(v): boolean => validateCardNumber(unmaskCardNumber(v))}
          required
        />
      </Grid>
      <Grid item xs={4}>
        <InputStyled
          inputMode="numeric"
          text="3 ou 4 dígitos"
          key="input_cvv"
          value={cvv}
          onChange={(v): void => onChangeCard({ ...card, cvv: v })}
          title="CVV"
          validate={validateCardCVV}
          required
        />
      </Grid>
      {method !== PaymentMethod.CARD && (
        <Grid item xs={12}>
          <InputValidateOnChange
            key="input_price_on_card"
            value={`${maskPrice(valueOnThisCard)}`}
            validate={(v): boolean => +v > 99}
            onChange={(v): void => changeValue(unmaskPrice(v))}
          />
        </Grid>
      )}
      {installmentsOptions.length !== 0 && (
        <Grid item>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              label="Parcelas"
              select
              value={`${installments}`}
              onChange={(e): void => onChangeCard({ ...card, installments: parseInt(e.target.value as string, 10) })}
            >
              {installmentsOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
      )}
      {onNext && (
        <Grid item xs={12}>
          <SaveButton disabled={!enableNext || paying} onClick={onNext}>
            {paying ? <Loader /> : 'Comprar Agora'}
          </SaveButton>
        </Grid>
      )}
    </Grid>
  );
};

export default Card;
