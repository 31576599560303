import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import EndCMP from '../components/End';
import { PaymentStatusTranslate } from '../constants/app';
import useCache from '../utils/useCache';
import { validateCustomer, validateStudentCustomer, validateAddress } from '../utils/validators';
import { Address, Customer } from '../types';

interface EndProps {
  status: string;
  planCode: string;
  afcCode?: string;
}

const End: React.FC<RouteComponentProps<EndProps>> = ({ history, match }) => {
  const [address] = useCache<Address>('address');
  const [customer] = useCache<Customer>('customer');
  const [boletoUrl] = useCache<string>('boletoUrl');
  const [pixQRCode] = useCache<string>('pixQRCode');
  const {
    params: { status, planCode, afcCode },
  } = match;
  const paymentStatus = PaymentStatusTranslate[status];
  const email = customer?.email || '';

  const onRetry = (): void => {
    const params = new URLSearchParams(window.location.search);
    const hasValidData =
      address &&
      customer &&
      validateCustomer(customer) &&
      validateStudentCustomer(customer) &&
      validateAddress(address);
    history.push(
      `/${status.includes('tutoria') || params.get('t') ? 'tutoria' : 'turma'}${
        status.includes('boleto-e-cartao') || params.get('f') ? '/boleto_e_cartao' : ''
      }/${planCode}${afcCode ? `/${afcCode}` : ''}${hasValidData ? '/payment?p=1' : '/lead'}`
    );
  };

  return (
    <EndCMP onRetry={onRetry} status={paymentStatus} email={email} boletoUrl={boletoUrl || ''} pixQRCode={pixQRCode} />
  );
};

export default End;
