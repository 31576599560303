import { PaymentStatus } from '../types';

export const PaymentStatusTranslate: Record<string, PaymentStatus> = {
  'sem-pagamento': PaymentStatus.NO_PAYMENT,
  'pix-sucesso': PaymentStatus.PIX_SUCCESS,
  'pix-falha': PaymentStatus.PIX_FAIL,
  'boleto-sucesso': PaymentStatus.BOLETO_SUCCESS,
  'boleto-falha': PaymentStatus.BOLETO_FAIL,
  'boleto-e-cartao-sucesso': PaymentStatus.BOLETO_AND_CARD_SUCCESS,
  'boleto-e-cartao-sem-aluno': PaymentStatus.BOLETO_AND_CARD_SUCCESS_NO_STUDENT,
  'boleto-e-cartao-sem-boleto': PaymentStatus.BOLETO_AND_CARD_SUCCESS_NO_BOLETO,
  'boleto-e-cartao-falha': PaymentStatus.BOLETO_AND_CARD_FAIL,
  'cartao-sucesso': PaymentStatus.CARD_SUCCESS,
  'cartao-sem-aluno': PaymentStatus.CARD_SUCCESS_NO_STUDENT,
  'cartao-falha': PaymentStatus.CARD_FAIL,
  'dois-cartoes-sucesso': PaymentStatus.TWO_CARD_SUCCESS,
  'dois-cartoes-sem-aluno': PaymentStatus.TWO_CARD_SUCCESS_NO_STUDENT,
  'dois-cartoes-sem-pagamento': PaymentStatus.TWO_CARD_SUCCESS_PAYMENT_FAIL,
  'dois-cartoes-sem-pagamento-aluno': PaymentStatus.TWO_CARD_SUCCESS_PAYMENT_FAIL_NO_STUDENT,
  'dois-cartoes-falha': PaymentStatus.TWO_CARD_FAIL,
  'rematricula-falha': PaymentStatus.REENROLLMENT_FAIL,
  'tutoria-elegivel-falha': PaymentStatus.ADVISOR_ELIGIBLE_FAIL,
  'tutoria-boleto-sucesso': PaymentStatus.ADVISOR_BOLETO_SUCCESS,
  'tutoria-cartao-sucesso': PaymentStatus.ADVISOR_CARD_SUCCESS,
  'tutoria-cartao-sem-tutor': PaymentStatus.ADVISOR_CARD_SUCCESS_NO_ADVISOR,
  'tutoria-boleto-e-cartao-sem-boleto': PaymentStatus.ADVISOR_BOLETO_AND_CARD_SUCCESS_NO_BOLETO,
  'tutoria-boleto-e-cartao-sucesso': PaymentStatus.ADVISOR_BOLETO_AND_CARD_SUCCESS,
  'tutoria-boleto-e-cartao-sem-tutor': PaymentStatus.ADVISOR_BOLETO_AND_CARD_SUCCESS_NO_ADVISOR,
  'tutoria-dois-cartoes-sucesso': PaymentStatus.ADVISOR_TWO_CARD_SUCCESS,
  'tutoria-dois-cartoes-sem-tutor': PaymentStatus.ADVISOR_TWO_CARD_SUCCESS_NO_ADVISOR,
  'tutoria-dois-cartoes-sem-pagamento': PaymentStatus.ADVISOR_TWO_CARD_SUCCESS_PAYMENT_FAIL,
  'tutoria-dois-cartoes-sem-pagamento-tutor': PaymentStatus.ADVISOR_TWO_CARD_SUCCESS_PAYMENT_FAIL_NO_ADVISOR,
  'atualizar-cartao-sucesso': PaymentStatus.UPDATE_CARD_SUCCESS,
  'atualizar-cartao-falha': PaymentStatus.UPDATE_CARD_FAIL,
};

export const EndStatusTranslate = Object.fromEntries(Object.entries(PaymentStatusTranslate).map(([k, v]) => [v, k]));

export const STATES = [
  {
    text: 'Acre',
    value: 'AC',
  },
  {
    text: 'Alagoas',
    value: 'AL',
  },
  {
    text: 'Amapá',
    value: 'AP',
  },
  {
    text: 'Amazonas',
    value: 'AM',
  },
  {
    text: 'Bahia',
    value: 'BA',
  },
  {
    text: 'Ceará',
    value: 'CE',
  },
  {
    text: 'Distrito Federal',
    value: 'DF',
  },
  {
    text: 'Espírito Santo',
    value: 'ES',
  },
  {
    text: 'Goiás',
    value: 'GO',
  },
  {
    text: 'Maranhão',
    value: 'MA',
  },
  {
    text: 'Mato Grosso',
    value: 'MT',
  },
  {
    text: 'Mato Grosso do Sul',
    value: 'MS',
  },
  {
    text: 'Minas Gerais',
    value: 'MG',
  },
  {
    text: 'Pará',
    value: 'PA',
  },
  {
    text: 'Paraíba',
    value: 'PB',
  },
  {
    text: 'Paraná',
    value: 'PR',
  },
  {
    text: 'Pernambuco',
    value: 'PE',
  },
  {
    text: 'Piauí',
    value: 'PI',
  },
  {
    text: 'Rio de Janeiro',
    value: 'RJ',
  },
  {
    text: 'Rio Grande do Norte',
    value: 'RN',
  },
  {
    text: 'Rio Grande do Sul',
    value: 'RS',
  },
  {
    text: 'Rondônia',
    value: 'RO',
  },
  {
    text: 'Roraima',
    value: 'RR',
  },
  {
    text: 'Santa Catarina',
    value: 'SC',
  },
  {
    text: 'São Paulo',
    value: 'SP',
  },
  {
    text: 'Sergipe',
    value: 'SE',
  },
  {
    text: 'Tocantins',
    value: 'TO',
  },
];

export enum CheckoutSteps {
  lead = 'lead',
  payment = 'payment',
}
