export const CHECKOUT_CUSTOMER_REGISTRATION_PORTAL = 'usuario-cadastrado-gabarito';
export const CHECKOUT_CUSTOMER_ENTRY = 'checkout-customer-entry';
export const CHECKOUT_CUSTOMER_REGISTRATION = 'checkout-customer-registration';
export const CHECKOUT_ADDRESS_REGISTRATION = 'checkout-address-registration';
export const CHECKOUT_PAYMENT_SUCCESS = 'checkout-payment-success';
export const CHECKOUT_PAYMENT_FAILED = 'checkout-payment-failed';
export const CHECKOUT_PAYMENT_RETRY = 'checkout-payment-retry';

export const CHECKOUT_PAYMENT_TRY = (method: string): string => `checkout-payment-try-${method}`;
export const CHECKOUT_PAYMENT_CHOSEN = (method: string): string => `checkout-payment-${method}-chosen`;

export const PORTAL_FOOTER_FORM = 'newsletter-footer';

export const CHECKOUT_RD_EVENTS = {
  'iniciou-checkout': 'iniciou-checkout',
  Lead_Checkout: 'Lead_Checkout',
  compra_confirmada: 'compra_confirmada',
  'atualizar-cartao-falha': 'atualizar-cartao-falha',
  'atualizar-cartao-sucesso': 'atualizar-cartao-sucesso',
  'boleto-e-cartao-falha': 'boleto-e-cartao-falha',
  'boleto-e-cartao-sem-aluno': 'boleto-e-cartao-sem-aluno',
  'boleto-e-cartao-sem-boleto': 'boleto-e-cartao-sem-boleto',
  'boleto-e-cartao-sucesso': 'boleto-e-cartao-sucesso',
  'boleto-falha': 'boleto-falha',
  'boleto-sucesso': 'boleto-sucesso',
  'cartao-falha': 'cartao-falha',
  'cartao-sem-aluno': 'cartao-sem-aluno',
  'cartao-sucesso': 'cartao-sucesso',
  'dois-cartoes-falha': 'dois-cartoes-falha',
  'dois-cartoes-sem-aluno': 'dois-cartoes-sem-aluno',
  'dois-cartoes-sem-pagamento': 'dois-cartoes-sem-pagamento',
  'dois-cartoes-sem-pagamento-aluno': 'dois-cartoes-sem-pagamento-aluno',
  'dois-cartoes-sucesso': 'dois-cartoes-sucesso',
  'pix-falha': 'pix-falha',
  'pix-sucesso': 'pix-sucesso',
  'sem-pagamento': 'sem-pagamento',
};
