import React, { FormEvent } from 'react';
import styled from 'styled-components';

import Grid from '@mui/material/Grid';

import { InputStyled } from './Input';
import { SaveButton } from './Button';
import Disclaimer from './Disclaimer';

import { unmaskPhone, maskPhone } from '../utils/format';
import { onCustomerNext } from '../utils/marketing';
import { validateEmail, validatePhone, validateString, validateStudentCustomer } from '../utils/validators';

import { Customer, Plan } from '../types';

const ContainerForm = styled.form`
  margin: 8px;
`;

interface CustomerProps {
  plan: Plan;
  customer: Customer;
  enableNext: boolean;
  onChange: (c: Customer) => void;
  onCheckAdvisorEligible: (email: string) => void;
  onNext: () => void;
}

const CustomerCMP: React.FC<CustomerProps> = ({
  plan,
  customer,
  enableNext,
  onChange,
  onCheckAdvisorEligible,
  onNext,
}) => {
  const { name, email, studentPhone } = customer;
  const maskedStudentPhone = maskPhone(studentPhone);

  const handleNext = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    window.scrollTo(0, 0);
    if (validateStudentCustomer(customer)) {
      onCustomerNext(customer, plan.alias);
    }
    onNext();
  };

  return (
    <ContainerForm onSubmit={handleNext}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputStyled
            key="input_name"
            value={name}
            onChange={(value): void => onChange({ ...customer, name: value })}
            text="Ex.: Maria Silva dos Santos"
            title="Nome completo do aluno"
            validate={validateString}
            required
            onInvalidMessage="Digite o seu nome"
          />
        </Grid>
        <Grid item xs={12}>
          <InputStyled
            key="input_email"
            value={email}
            onBlur={(value): void => onCheckAdvisorEligible(value)}
            onChange={(value): void => onChange({ ...customer, email: value && value.toLowerCase().trim() })}
            text="Ex.: emaildoaluno@dominio.com"
            title="Email do aluno"
            type="email"
            validate={validateEmail}
            required
            onInvalidMessage="Digite um email válido"
          />
        </Grid>
        <Grid item xs={12}>
          <InputStyled
            type="text"
            inputMode="numeric"
            key="input_student_phone"
            value={maskedStudentPhone}
            onChange={(value): void => onChange({ ...customer, studentPhone: unmaskPhone(value) })}
            text="Ex.:00999999999"
            title="Celular"
            validate={(value): boolean => validatePhone(unmaskPhone(value))}
            required
            onInvalidMessage="Digite um celular válido"
          />
        </Grid>
        <Grid item xs={12}>
          <Disclaimer />
        </Grid>
        <Grid item xs={12}>
          <SaveButton type="submit" fullWidth disabled={!enableNext}>
            Continuar
          </SaveButton>
        </Grid>
      </Grid>
    </ContainerForm>
  );
};

export default CustomerCMP;
