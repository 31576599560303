import { createTheme } from '@mui/material/styles';

export const KUADRO_GREEN = '#1d6b3f';
export const KUADRO_ORANGE = '#ffa000';

export const KUADRO_THEME = createTheme({
  palette: {
    primary: { main: KUADRO_GREEN },
    secondary: { main: KUADRO_ORANGE, contrastText: '#fff' },
  },
});
