import React, { useState } from 'react';
import styled from 'styled-components';
import QRCode from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import useMediaQuery from '@mui/material/useMediaQuery';
import { CDNImage, CheckoutCDNImage, Image } from './Image';
import { makeCheckoutCDNURL } from '../images-cdn';
import { LOGO_HD } from '../constants/logos';

import { PaymentStatus } from '../types';

const Container = styled.div`
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  :hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  text-align: center;
`;

const ImageBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${makeCheckoutCDNURL('checkout-background-1920.png')});
  background-color: #2a9a5b;
  display: flex;
  background-position-x: center;
  @media (min-width: 600px) {
    background-position-x: right;
  }
`;

const ImageCongratulations = styled.div`
  display: none;
  bottom: 0;
  right: 50px;
  position: absolute;
  @media (min-width: 600px) {
    max-width: 550px;
    margin-left: 300px;
    display: flex;
  }
`;

const ImagePhoneDevice = styled.div`
  width: 90%;
  justify-content: center;
  display: flex;
  bottom: 0;
  position: absolute;
  @media (min-width: 600px) {
    display: none;
  }
`;

const NewHeader = styled.div`
  width: 100%;
  max-width: 550px;
  height: min-content;
  margin-left: 20px;
  margin-top: 20px;
  @media (min-width: 600px) {
    margin-left: 60px;
  }
`;

const Holder = styled.div`
  max-width: 1220px;
  justify-content: space-between;
  display: flex;
  margin: 0;
  @media (min-width: 600px) {
    margin: auto;
  }
`;

const Title = styled.div`
  text-align: left;
  color: #ffffff;
  font-family: Roboto;
  font-size: 72px;
  font-weight: 700;
  margin-bottom: 30px;
  @media (max-width: 480px) {
    font-size: 48px;
  }
`;

const SubTitle = styled.div`
  text-align: left;
  font-size: 32px;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 50px;
  @media (max-width: 480px) {
    font-size: 18px;
    margin-bottom: 10px;
  }
`;

const Div = styled.div`
  color: #1d6a3f;
  background-color: #ffffff;
  font-size: 21px;
  border-radius: 4px;
  padding: 20px;
  max-width: 440px;
  margin: auto;
  margin-bottom: 50px;
  @media (max-width: 480px) {
    font-size: 16px;
    max-width: 325px;
    margin-left: 6px;
    margin-bottom: 25px;
  }
`;

const Span = styled.div`
  margin: 50px 0px;
  font-size: 1.2em;
  text-transform: uppercase;
  padding: 0px 10px 10px 10px;
`;

const Button = styled.button`
  text-decoration: none;
  color: white;
  margin: 2% 0% 2% 0%;
  background: linear-gradient(to bottom, rgba(124, 208, 98, 1) 0%, #6fbb59 100%);
  padding: 10px 30px;
  display: inline-block;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.21);
  border-bottom: 4px solid rgba(0, 0, 0, 0.21);
  border-radius: 4px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  :active {
    background: #72b08e;
  }
  :disabled {
    cursor: default;
    background: rgb(221, 221, 221);
  }
`;

const GradientHr = styled.div`
  padding-top: 5px;
  margin-top: 5px;
  background-image: linear-gradient(to right, rgba(200, 200, 200, 0), rgb(200, 200, 200), rgba(200, 200, 200, 0));
  background-size: 100% 1px;
  background-repeat: no-repeat;
  background-position: center top;
`;

const Header = styled.div`
  width: 100%;
  padding: 20px 0px;
`;

interface RetryProps {
  onRetry: () => void;
}

// Total failure
const PaymentCardFail: React.FC<RetryProps> = ({ onRetry }) => (
  <Container>
    <Header>
      <Image src={LOGO_HD} width="50%" />
    </Header>
    <GradientHr />
    <Span>O pagamento não foi autorizado. Por favor, verique os dados e tente novamente.</Span>
    <GradientHr />
    <Button onClick={onRetry}>Verificar dados</Button>
  </Container>
);

const PaymentBoletoFail: React.FC<RetryProps> = ({ onRetry }) => (
  <Container>
    <Header>
      <Image src={LOGO_HD} width="50%" />
    </Header>
    <GradientHr />
    <Span>Não fomos capazes de gerar o boleto. Por favor, verifique os dados e tente novamente.</Span>
    <GradientHr />
    <Button onClick={onRetry}>Verificar dados</Button>
  </Container>
);

const PaymentPixFail: React.FC<RetryProps> = ({ onRetry }) => (
  <Container>
    <Header>
      <Image src={LOGO_HD} width="50%" />
    </Header>
    <GradientHr />
    <Span>Não fomos capazes de gerar o PIX. Por favor, verifique os dados e tente novamente.</Span>
    <GradientHr />
    <Button onClick={onRetry}>Verificar dados</Button>
  </Container>
);

interface EmailProps {
  email: string;
}

// Total success
const PaymentCardSuccess: React.FC<EmailProps> = ({ email }) => {
  const isPhoneDevice = useMediaQuery('(max-width:480px)');
  return (
    <ImageBackground>
      <Holder>
        <NewHeader>
          <CDNImage src="logokuadro.svg" width={isPhoneDevice ? '47px' : '76px'} />
          <div style={isPhoneDevice ? { marginTop: 20 } : { marginTop: 50 }}>
            <Title>Parabéns</Title>
            <SubTitle>
              Você realizou o primeiro e o mais <strong>importante passo para a sua aprovação. </strong>
            </SubTitle>
            <Div>
              Confira os dados de acesso do aluno{' '}
              {email ? (
                <span>
                  em
                  <strong> {email}</strong>
                </span>
              ) : (
                'no email cadastrado'
              )}
            </Div>
          </div>
          {isPhoneDevice && (
            <ImagePhoneDevice>
              <CheckoutCDNImage src="Bruna%20Vertemati%202.png" width="206px" />
            </ImagePhoneDevice>
          )}
        </NewHeader>
        <ImageCongratulations>
          <CheckoutCDNImage src="Bruna%20Vertemati%202.png" width="406px" />
        </ImageCongratulations>
      </Holder>
    </ImageBackground>
  );
};

const PaymentAdvisorCardSuccess: React.FC = () => (
  <Container>
    <Header>
      <Image src={LOGO_HD} width="50%" />
    </Header>
    <GradientHr />
    <Span>Parabéns! Você adquiriu a tutoria.</Span>
    <GradientHr />
  </Container>
);

interface BoletoProps {
  boletoUrl: string;
}

const PaymentBoletoSuccess: React.FC<BoletoProps> = ({ boletoUrl }) => {
  const isPhoneDevice = useMediaQuery('(max-width:480px)');
  return (
    <ImageBackground>
      <Holder>
        <NewHeader>
          <CDNImage src="logokuadro.svg" width={isPhoneDevice ? '47px' : '76px'} />
          <div style={{ marginTop: 50 }}>
            <Title>Parabéns</Title>
            <SubTitle>
              Você realizou o primeiro e o mais <strong>importante passo para a sua aprovação. </strong>
            </SubTitle>
            <Div>
              O seu acesso será enviado por e-mail após a comprovação de
              <strong> pagamento do boleto.</strong>
            </Div>
            <SubTitle>
              Para baixar o boleto,
              {'  '}
              <a target="_blank" rel="noopener noreferrer" href={boletoUrl}>
                clique aqui
              </a>
            </SubTitle>
          </div>
          {isPhoneDevice && (
            <ImagePhoneDevice>
              <CheckoutCDNImage src="Bruna%20Vertemati%202.png" width="206px" />
            </ImagePhoneDevice>
          )}
        </NewHeader>
        <ImageCongratulations>
          <CheckoutCDNImage src="Bruna%20Vertemati%202.png" width="406px" />
        </ImageCongratulations>
      </Holder>
    </ImageBackground>
  );
};

interface PixProps {
  pixQRCode: string;
}

const PaymentPixSuccess: React.FC<PixProps> = ({ pixQRCode }) => {
  const [copied, setCopied] = useState(false);
  const onCopy = (): void => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };
  const isPhoneDevice = useMediaQuery('(max-width:480px)');
  return (
    <ImageBackground>
      <Holder>
        <NewHeader>
          <CDNImage src="logokuadro.svg" width={isPhoneDevice ? '47px' : '76px'} />
          <div style={{ marginTop: 50 }}>
            <Title>Parabéns</Title>
            <SubTitle>
              Você realizou o primeiro e o mais <strong>importante passo para a sua aprovação. </strong>
            </SubTitle>
            <Div>
              Use o QR Code abaixo para pagar com o PIX. O seu acesso será enviado por e-mail após a confirmação de
              <strong> pagamento do PIX.</strong>
            </Div>
            <QRCode size={256} includeMargin value={pixQRCode} />
            <SubTitle>
              <CopyToClipboard text={pixQRCode} onCopy={onCopy}>
                <Button onClick={onCopy}>{copied ? 'Copiado' : 'Copiar Pix'}</Button>
              </CopyToClipboard>
            </SubTitle>
          </div>
          {isPhoneDevice && (
            <ImagePhoneDevice>
              <CheckoutCDNImage src="Bruna%20Vertemati%202.png" width="206px" />
            </ImagePhoneDevice>
          )}
        </NewHeader>
        <ImageCongratulations>
          <CheckoutCDNImage src="Bruna%20Vertemati%202.png" width="406px" />
        </ImageCongratulations>
      </Holder>
    </ImageBackground>
  );
};

const PaymentAdvisorBoletoSuccess: React.FC<BoletoProps> = ({ boletoUrl }) => (
  <Container>
    <Header>
      <Image src={LOGO_HD} width="50%" />
    </Header>
    <GradientHr />
    <Span>Seu tutor será atribuido assim que seu pagamento for processado no nosso sistema.</Span>
    <Span>
      Para baixar o boleto,
      {'  '}
      <a target="_blank" rel="noopener noreferrer" href={boletoUrl}>
        clique aqui
      </a>
    </Span>
  </Container>
);

const PaymentBoletoAndCardSuccess: React.FC<BoletoProps> = ({ boletoUrl }) => {
  const isPhoneDevice = useMediaQuery('(max-width:480px)');
  return (
    <ImageBackground>
      <Holder>
        <NewHeader>
          <CDNImage src="logokuadro.svg" width={isPhoneDevice ? '47px' : '76px'} />
          <div style={{ marginTop: 50 }}>
            <Title>Parabéns</Title>
            <SubTitle>
              Você realizou o primeiro e o mais <strong>importante passo para a sua aprovação. </strong>
            </SubTitle>
            <Div>
              O seu acesso será enviado por e-mail após a comprovação de
              <strong> pagamento do boleto.</strong>
            </Div>
            <SubTitle>
              Para baixar o boleto,
              {'  '}
              <a target="_blank" rel="noopener noreferrer" href={boletoUrl}>
                clique aqui
              </a>
            </SubTitle>
          </div>
          {isPhoneDevice && (
            <ImagePhoneDevice>
              <CheckoutCDNImage src="Bruna%20Vertemati%202.png" width="206px" />
            </ImagePhoneDevice>
          )}
        </NewHeader>
        <ImageCongratulations>
          <CheckoutCDNImage src="Bruna%20Vertemati%202.png" width="406px" />
        </ImageCongratulations>
      </Holder>
    </ImageBackground>
  );
};

const PaymentAdvisorBoletoAndCardSuccess: React.FC<BoletoProps> = ({ boletoUrl }) => (
  <Container>
    <Header>
      <Image src={LOGO_HD} width="50%" />
    </Header>
    <GradientHr />
    <Span>Seu tutor será atribuido assim que seu pagamento for processado no nosso sistema.</Span>
    <Span>
      Para baixar o boleto,
      {'  '}
      <a target="_blank" rel="noopener noreferrer" href={boletoUrl}>
        clique aqui
      </a>
    </Span>
  </Container>
);

// Parcial success
const PaymentTwoCardSuccessPaymentFail: React.FC = () => (
  <Container>
    <Header>
      <Image src={LOGO_HD} width="50%" />
    </Header>
    <GradientHr />
    <Span>
      Bem-vindo ao Kuadro! Os dados de acesso serão enviados para o email do aluno. Um dos seus cartões não autorizou a
      compra, mas não se preocupe nossa equipe entrará em contato.
    </Span>
  </Container>
);

const PaymentAdvisorTwoCardSuccessPaymentFail: React.FC = () => (
  <Container>
    <Header>
      <Image src={LOGO_HD} width="50%" />
    </Header>
    <GradientHr />
    <Span>Um dos seus cartões não autorizou a compra, mas não se preocupe nossa equipe entrará em contato.</Span>
  </Container>
);

const PaymentTwoCardSuccessPaymentFailNoStudent: React.FC = () => (
  <Container>
    <Header>
      <Image src={LOGO_HD} width="50%" />
    </Header>
    <GradientHr />
    <Span>
      Um dos seus cartões não autorizou a compra, mas não se preocupe nossa equipe entrará em contato para liberar o
      acesso.
    </Span>
  </Container>
);

const PaymentAdvisorTwoCardSuccessPaymentFailNoAdvisor: React.FC = () => (
  <Container>
    <Header>
      <Image src={LOGO_HD} width="50%" />
    </Header>
    <GradientHr />
    <Span>
      Um dos seus cartões não autorizou a compra, mas não se preocupe nossa equipe entrará em contato para liberar a
      tutoria.
    </Span>
  </Container>
);

const PaymentCardSuccessNoStudent: React.FC = () => (
  <Container>
    <Header>
      <Image src={LOGO_HD} width="50%" />
    </Header>
    <GradientHr />
    <Span>Bem-vindo ao Kuadro! Os dados de acesso serão enviados para o email do aluno em até 24 horas.</Span>
  </Container>
);

const PaymentAdvisorCardSuccessNoAdvisor: React.FC = () => (
  <Container>
    <Header>
      <Image src={LOGO_HD} width="50%" />
    </Header>
    <GradientHr />
    <Span>Parabéns! Você adquiriu a tutoria. Você terá acesso a tutoria em até 24 horas.</Span>
  </Container>
);

const ReenrollmentFail: React.FC<RetryProps> = ({ onRetry }) => (
  <Container>
    <Header>
      <Image src={LOGO_HD} width="50%" />
    </Header>
    <GradientHr />
    <Span>Esse aluno não está apto para rematrícula.</Span>
    <GradientHr />
    <Button onClick={onRetry}>Verificar dados</Button>
  </Container>
);

const AdvisorFail: React.FC<RetryProps> = ({ onRetry }) => (
  <Container>
    <Header>
      <Image src={LOGO_HD} width="50%" />
    </Header>
    <GradientHr />
    <Span>Esse aluno não está apto para a compra da tutoria.</Span>
    <GradientHr />
    <Button onClick={onRetry}>Verificar dados</Button>
  </Container>
);

const PaymentBoletoAndCardSuccessNoBoleto: React.FC = () => (
  <Container>
    <Header>
      <Image src={LOGO_HD} width="50%" />
    </Header>
    <GradientHr />
    <Span>Bem-vindo ao Kuadro! Os dados de acesso serão enviados para o email do aluno.</Span>
    <Span>Não foi possível gerar o boleto, mas não se preocupe nossa equipe irá entrar em contato com você.</Span>
  </Container>
);

const PaymentAdvisorBoletoAndCardSuccessNoBoleto: React.FC = () => (
  <Container>
    <Header>
      <Image src={LOGO_HD} width="50%" />
    </Header>
    <GradientHr />
    <Span>
      Seu cartão foi aprovado, mas não foi possível gerar o boleto, mas não se preocupe nossa equipe irá entrar em
      contato com você.
    </Span>
  </Container>
);

const UpdateCardSuccess: React.FC = () => (
  <Container>
    <Header>
      <Image src={LOGO_HD} width="50%" />
    </Header>
    <GradientHr />
    <Span>Cartão atualizado com sucesso.</Span>
    <GradientHr />
  </Container>
);

const UpdateCardFail: React.FC<RetryProps> = ({ onRetry }) => (
  <Container>
    <Header>
      <Image src={LOGO_HD} width="50%" />
    </Header>
    <GradientHr />
    <Span>Falha ao atualizar o cartão.</Span>
    <GradientHr />
    <Button onClick={onRetry}>Verificar dados</Button>
  </Container>
);

interface EndProps {
  status: PaymentStatus;
  boletoUrl: string;
  pixQRCode?: string;
  onRetry: () => void;
  email: string;
}

const End: React.FC<EndProps> = ({ status, pixQRCode, boletoUrl, onRetry, email }) => {
  switch (status) {
    case PaymentStatus.ADVISOR_ELIGIBLE_FAIL:
      return <AdvisorFail onRetry={onRetry} />;
    case PaymentStatus.REENROLLMENT_FAIL:
      return <ReenrollmentFail onRetry={onRetry} />;
    case PaymentStatus.PIX_SUCCESS:
      return <PaymentPixSuccess pixQRCode={pixQRCode || ''} />;
    case PaymentStatus.BOLETO_SUCCESS:
    case PaymentStatus.BOLETO_AND_CARD_SUCCESS_NO_STUDENT:
      return <PaymentBoletoSuccess boletoUrl={boletoUrl} />;
    case PaymentStatus.BOLETO_AND_CARD_SUCCESS_NO_BOLETO:
      return <PaymentBoletoAndCardSuccessNoBoleto />;
    case PaymentStatus.BOLETO_AND_CARD_SUCCESS:
      return <PaymentBoletoAndCardSuccess boletoUrl={boletoUrl} />;
    case PaymentStatus.BOLETO_FAIL:
      return <PaymentBoletoFail onRetry={onRetry} />;
    case PaymentStatus.PIX_FAIL:
      return <PaymentPixFail onRetry={onRetry} />;
    case PaymentStatus.CARD_SUCCESS:
    case PaymentStatus.TWO_CARD_SUCCESS:
      return <PaymentCardSuccess email={email} />;
    case PaymentStatus.TWO_CARD_SUCCESS_NO_STUDENT:
    case PaymentStatus.CARD_SUCCESS_NO_STUDENT:
      return <PaymentCardSuccessNoStudent />;
    case PaymentStatus.TWO_CARD_SUCCESS_PAYMENT_FAIL:
      return <PaymentTwoCardSuccessPaymentFail />;
    case PaymentStatus.TWO_CARD_SUCCESS_PAYMENT_FAIL_NO_STUDENT:
      return <PaymentTwoCardSuccessPaymentFailNoStudent />;
    case PaymentStatus.UPDATE_CARD_SUCCESS:
      return <UpdateCardSuccess />;
    case PaymentStatus.UPDATE_CARD_FAIL:
      return <UpdateCardFail onRetry={onRetry} />;
    case PaymentStatus.ADVISOR_BOLETO_SUCCESS:
    case PaymentStatus.ADVISOR_BOLETO_AND_CARD_SUCCESS_NO_ADVISOR:
      return <PaymentAdvisorBoletoSuccess boletoUrl={boletoUrl} />;
    case PaymentStatus.ADVISOR_CARD_SUCCESS:
    case PaymentStatus.ADVISOR_TWO_CARD_SUCCESS:
      return <PaymentAdvisorCardSuccess />;
    case PaymentStatus.ADVISOR_TWO_CARD_SUCCESS_NO_ADVISOR:
    case PaymentStatus.ADVISOR_CARD_SUCCESS_NO_ADVISOR:
      return <PaymentAdvisorCardSuccessNoAdvisor />;
    case PaymentStatus.ADVISOR_BOLETO_AND_CARD_SUCCESS_NO_BOLETO:
      return <PaymentAdvisorBoletoAndCardSuccessNoBoleto />;
    case PaymentStatus.ADVISOR_BOLETO_AND_CARD_SUCCESS:
      return <PaymentAdvisorBoletoAndCardSuccess boletoUrl={boletoUrl} />;
    case PaymentStatus.ADVISOR_TWO_CARD_SUCCESS_PAYMENT_FAIL:
      return <PaymentAdvisorTwoCardSuccessPaymentFail />;
    case PaymentStatus.ADVISOR_TWO_CARD_SUCCESS_PAYMENT_FAIL_NO_ADVISOR:
      return <PaymentAdvisorTwoCardSuccessPaymentFailNoAdvisor />;
    case PaymentStatus.NO_PAYMENT:
    case PaymentStatus.CARD_FAIL:
    case PaymentStatus.TWO_CARD_FAIL:
    case PaymentStatus.BOLETO_AND_CARD_FAIL:
    default:
      return <PaymentCardFail onRetry={onRetry} />;
  }
};

export default End;
