import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';

import Card from '../components/Card';
import Loader from '../components/Loader';
import { Image, CDNImage } from '../components/Image';
import End from '../components/End';

import { Plan, Coupon, PayMissingPayload, PaymentStatus, PaymentInfo, CardInfo, PaymentMethod } from '../types';

import { getMissingPayment, processMissingPay } from '../api';
import { LOGO } from '../constants/logos';
import { validateString, validateCardNumber, validateCardCVV, validateCardExpirationDate } from '../utils/validators';
import { getFullPrice, getTotalPrice } from '../utils/price';
import { priceToShow } from '../utils/format';

const Canvas = styled.div`
  background-color: white;
  width: 100%;
  max-width: 360px;
  padding: 10px;
  box-sizing: border-box;
`;

const TitleLine = styled.div`
  justify-content: space-between;
  padding: 0 0 5px 0;
  display: flex;
`;

const Border = styled.div`
  background-image: linear-gradient(to right, rgba(200, 200, 200, 0), rgba(200, 200, 200, 1), rgba(200, 200, 200, 0));
  background-size: 100% 1px;
  background-repeat: no-repeat;
  background-position: top center;
  height: 1px;
  padding: 0 0 5px 0;
`;

const CreditCardsFooter = styled.div`
  height: 80px;
  background-color: #f1f1f1;
  margin: 0px 15px 15px 15px;
  border: 1px solid rgba(0, 0, 0, 0.21);
  border-top: none;
`;

const LogoCard = styled.div`
  width: 42px;
  height: 30px;
  background-color: white;
  margin-right: 5px;
`;

const CreditCardsHeader = styled.div`
  background-color: #f1f1f1;
  border: 1px solid rgba(0, 0, 0, 0.21);
  border-bottom: none;
  margin: 0px 1rem;
  padding: 1rem;
`;

const Flag = styled.div`
  top: 40px;
  background-color: #7cd063;
  padding: 10px 12px 10px 16px;
  width: 80%;
  left: -10px;
  height: 42px;
  box-sizing: border-box;
  &::before {
    position: absolute;
    content: ' ';
    left: 0;
    display: block;
    border-bottom: 10px;
    border-left: 16px solid transparent;
    border-right: 0 solid transparent;
    border-top: 10px solid #56a03f;
    top: 42px;
  }
  &::after {
    content: ' ';
    background-color: black;
    width: 25px;
    height: 25px;
  }
`;

const FlagComplement = styled.div`
  background-color: #f1f1f1;
  height: 20px;
  width: 52%;
`;

const FlagContainer = styled.div`
  display: flex;
  border-right: 1px solid rgba(0, 0, 0, 0.21);
  width: 95.4%;
  position: relative;
`;

const SafePlace = styled.span`
  vertical-align: middle;
  color: white;
  font-weight: bold;
  inline-size: max-content;
`;

const Box = styled.div`
  background-color: #fff;
  display: flex;
  margin: 0px 15px 0px 15px;
  border: 1px solid rgba(0, 0, 0, 0.21);
  padding: 15px 20px;
  flex-direction: column;
  border-bottom: white;
  border-top: white;
`;

interface TitleProps {
  paid: number;
  plan: Plan;
  coupon?: Coupon;
  withoutFullDiscount: boolean;
}

const Title: React.FC<TitleProps> = ({ plan, coupon, paid, withoutFullDiscount }) => {
  const fullPrice = getFullPrice(plan, coupon, withoutFullDiscount);
  const totalPrice = getTotalPrice(plan, coupon);
  return (
    <div>
      <CreditCardsHeader>
        <Image src={LOGO} width="200px" height="" />
      </CreditCardsHeader>
      <FlagContainer>
        <Flag>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CDNImage src="/shield-alt-solid.svg" width="25px" />
            <SafePlace>COMPRA 100% SEGURA</SafePlace>
          </div>
        </Flag>
        <FlagComplement />
      </FlagContainer>
      <Box>
        <TitleLine>
          <span>Pago:</span>
          <span>{priceToShow(paid)}</span>
        </TitleLine>
        <Border />
        <TitleLine>
          <span>
            Restante: 1x R$ {priceToShow(fullPrice)} ou {plan.maxInstallments}x de R${' '}
            {priceToShow(totalPrice / plan.maxInstallments)}
          </span>
        </TitleLine>
      </Box>
      <CreditCardsFooter>
        <div style={{ paddingTop: '7px', display: 'flex', justifyContent: 'center' }}>
          <LogoCard>
            <CDNImage src="/visa.svg" width="43px" height="33px" />
          </LogoCard>
          <LogoCard>
            <CDNImage src="/masterCard.svg" width="43px" height="33px" />
          </LogoCard>
          <LogoCard>
            <CDNImage src="/americanExpress.svg" width="43px" height="33px" />
          </LogoCard>
          <LogoCard>
            <CDNImage src="/elo.svg" width="43px" height="33px" />
          </LogoCard>
        </div>
        <div style={{ paddingTop: '7px', display: 'flex', justifyContent: 'center' }}>
          <LogoCard>
            <CDNImage src="/boleto.svg" width="43px" height="33px" />
          </LogoCard>
          <LogoCard>
            <CDNImage src="/Hiper.png" width="41px" height="28px" />
          </LogoCard>
          <LogoCard>
            <CDNImage src="/Hiper2.png" width="43px" height="33px" />
          </LogoCard>
        </div>
      </CreditCardsFooter>
    </div>
  );
};

interface PayMissingProps {
  enrollmentId: string;
}

const validateCard = (card: CardInfo): boolean => {
  if (!validateString(card.name)) return false;
  if (Number.isNaN(card.installments)) return false;
  if (Number.isNaN(card.value)) return false;
  if (!validateCardExpirationDate(card.expirationDate)) return false;
  if (!validateCardNumber(card.number)) return false;
  if (!validateCardCVV(card.cvv)) return false;
  return true;
};

const PayMissing: React.FC<RouteComponentProps<PayMissingProps>> = ({
  match: {
    params: { enrollmentId },
  },
}) => {
  const [state, setState] = useState<PayMissingPayload>();
  const [paying, setPaying] = useState<boolean>(false);
  const [payment, setPayment] = useState<PaymentInfo>({
    method: PaymentMethod.CARD,
    cards: [
      {
        name: '',
        number: '',
        cvv: '',
        expirationDate: '',
        installments: 1,
        value: 0,
      },
    ],
  });
  const [paid, setPaid] = useState<PaymentStatus>(PaymentStatus.NO_PAYMENT);

  useEffect(() => {
    getMissingPayment(enrollmentId)
      .then(setState)
      .catch(() => {});
  }, [enrollmentId]);

  if (!state) {
    return <Loader />;
  }

  const { plan, afc, amount, payFull } = state;

  const planToSend = { ...plan };
  if (plan.type !== 'MONTHLY') {
    const purchaseTotalPrice = plan.price * plan.maxInstallments;
    const missingPayment = purchaseTotalPrice - amount;
    planToSend.price = missingPayment / plan.maxInstallments;
    if (payFull) {
      planToSend.fullPrice -= amount;
    } else {
      planToSend.fullPrice = planToSend.price * plan.maxInstallments;
    }
  }

  const onPay = (): void => {
    setPaying(true);
    processMissingPay(enrollmentId, payment)
      .then(() => {
        if (plan.type === 'ADVISOR') setPaid(PaymentStatus.ADVISOR_CARD_SUCCESS);
        else setPaid(PaymentStatus.CARD_SUCCESS);
      })
      .catch(() => {
        setPaid(PaymentStatus.CARD_FAIL);
      })
      .then(() => setPaying(false));
  };

  if (paid !== PaymentStatus.NO_PAYMENT)
    return <End boletoUrl="" email="" onRetry={(): void => setPaid(PaymentStatus.NO_PAYMENT)} status={paid} />;

  const validCard = validateCard(payment.cards[0]);

  return (
    <Canvas>
      <Title plan={planToSend} coupon={afc} paid={amount} withoutFullDiscount={!payFull} />
      <Card
        enableNext={validCard}
        payment={payment}
        plan={planToSend}
        paying={paying}
        coupon={afc}
        withoutFullDiscount={!payFull}
        onChangeCard={(c): void => setPayment({ ...payment, cards: [c] })}
        onNext={onPay}
      />
    </Canvas>
  );
};

export default PayMissing;
