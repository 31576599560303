import { STAGING, DISABLE_CONVERSIONS } from '../env';
import { sendToRD } from '../api';
// TODO: check this function use
import { getFullPrice } from './price';
import {
  CHECKOUT_ADDRESS_REGISTRATION,
  CHECKOUT_PAYMENT_FAILED,
  CHECKOUT_PAYMENT_RETRY,
  CHECKOUT_PAYMENT_SUCCESS,
  CHECKOUT_PAYMENT_TRY,
  CHECKOUT_RD_EVENTS,
} from '../constants/marketing';
import { Customer, Address, Plan, Coupon, PaymentMethod, PaymentStatus } from '../types';

declare global {
  interface Window {
    ga: (s: string, r: Record<string, unknown>) => void;
    kc: (s2: string, r?: Record<string, unknown>) => void;
  }
}

const kc = (s2: string, r?: Record<string, unknown>): void => {
  if (window.kc) window.kc(s2, r);
  else setTimeout(() => kc(s2, r), 1000);
};

const ga = (s: string, r: Record<string, unknown>): void => {
  if (window.ga) window.ga(s, r);
  else setTimeout(() => ga(s, r), 1000);
};

export const onPageView = (): void => {
  if (STAGING) return;
  if (DISABLE_CONVERSIONS) return;
  kc('PageView');
};

export const onCheckoutBegin = (): void => {
  if (STAGING) return;
  if (DISABLE_CONVERSIONS) return;
  ga('send', {
    hitType: 'event',
    eventCategory: 'Checkout',
    eventAction: 'click',
    eventLabel: 'iniciou-checkout',
  });
  kc('InitiateCheckout');
};

export const onCustomerBeginWithData = ({ email, name, phone }: Customer, planAlias: string): void => {
  if (STAGING) return;
  if (DISABLE_CONVERSIONS) return;
  sendToRD(email, CHECKOUT_RD_EVENTS['iniciou-checkout'], { name, phone, planAlias });
};

export const onCustomerNext = ({ email, name, phone, studentPhone }: Customer, planAlias: string): void => {
  if (STAGING) return;
  if (DISABLE_CONVERSIONS) return;
  sendToRD(email, CHECKOUT_RD_EVENTS.Lead_Checkout, { name, phone: studentPhone || phone, planAlias });
  ga('send', {
    hitType: 'event',
    eventCategory: 'Cadastro',
    eventAction: 'click',
    eventLabel: 'usuario-cadastrado',
  });
};

export const onAddressNext = (
  { email, name, phone, studentPhone }: Customer,
  { city, state }: Address,
  plan: Plan,
  afc?: Coupon
): void => {
  if (STAGING) return;
  if (DISABLE_CONVERSIONS) return;
  sendToRD(email, CHECKOUT_ADDRESS_REGISTRATION, {
    city,
    state,
    name,
    phone: studentPhone || phone,
    planAlias: plan.alias,
  });
  kc('CompleteRegistration', { value: getFullPrice(plan, afc) / 100, currency: 'BRL' });
};

export const onPaymentMethodChosen = (): void => {
  // sendToRD(email, CHECKOUT_PAYMENT_CHOSEN(method));
};

export const onPaymentTry = ({ email, name, phone, studentPhone }: Customer, method: string, plan: Plan): void => {
  if (STAGING) return;
  if (DISABLE_CONVERSIONS) return;
  sendToRD(email, CHECKOUT_PAYMENT_TRY(method), { name, phone: studentPhone || phone, planAlias: plan.alias });
  ga('send', {
    hitType: 'event',
    eventCategory: 'Checkout',
    eventAction: 'click',
    eventLabel: 'iniciou-compra',
  });
  ga('send', {
    hitType: 'event',
    eventCategory: 'Pagamento',
    eventAction: 'click',
    eventLabel: method,
  });
  kc('AddPaymentInfo');
};

export const onPaymentConfirm = (
  { email, name, phone, studentPhone }: Customer,
  { city, state }: Address,
  plan: Plan
): void => {
  if (STAGING) return;
  if (DISABLE_CONVERSIONS) return;
  if (plan.type !== 'ADVISOR') {
    sendToRD(email, CHECKOUT_RD_EVENTS.compra_confirmada, {
      name,
      phone: studentPhone || phone,
      city,
      state,
      planAlias: plan.alias,
    });
  }
};

export const onPaymentSuccess = (
  { email, name, phone, studentPhone }: Customer,
  { city, state }: Address,
  plan: Plan,
  afc: Coupon | undefined,
  paymentStatus: PaymentStatus
): void => {
  if (STAGING) return;
  if (DISABLE_CONVERSIONS) return;
  if (plan.type !== 'ADVISOR') {
    let event = CHECKOUT_PAYMENT_SUCCESS;
    switch (paymentStatus) {
      case PaymentStatus.BOLETO_AND_CARD_SUCCESS_NO_STUDENT: {
        event = CHECKOUT_RD_EVENTS['boleto-e-cartao-sem-aluno'];
        break;
      }
      case PaymentStatus.BOLETO_AND_CARD_SUCCESS_NO_BOLETO: {
        event = CHECKOUT_RD_EVENTS['boleto-e-cartao-sem-boleto'];
        break;
      }
      case PaymentStatus.CARD_SUCCESS: {
        event = CHECKOUT_RD_EVENTS['cartao-sucesso'];
        break;
      }
      case PaymentStatus.CARD_SUCCESS_NO_STUDENT: {
        event = CHECKOUT_RD_EVENTS['cartao-sem-aluno'];
        break;
      }
      case PaymentStatus.TWO_CARD_SUCCESS: {
        event = CHECKOUT_RD_EVENTS['dois-cartoes-sucesso'];
        break;
      }
      case PaymentStatus.TWO_CARD_SUCCESS_NO_STUDENT: {
        event = CHECKOUT_RD_EVENTS['dois-cartoes-sem-aluno'];
        break;
      }
      case PaymentStatus.TWO_CARD_SUCCESS_PAYMENT_FAIL: {
        event = CHECKOUT_RD_EVENTS['dois-cartoes-sem-pagamento'];
        break;
      }
      case PaymentStatus.TWO_CARD_SUCCESS_PAYMENT_FAIL_NO_STUDENT: {
        event = CHECKOUT_RD_EVENTS['dois-cartoes-sem-pagamento-aluno'];
        break;
      }
    }
    sendToRD(email, event, { name, phone: studentPhone || phone, city, state, planAlias: plan.alias });
  }
  kc('Purchase', { value: getFullPrice(plan, afc) / 100, currency: 'BRL' });
};

export const onPaymentFail = (
  { email, name, phone, studentPhone }: Customer,
  { city, state }: Address,
  method: PaymentMethod,
  plan: Plan
): void => {
  if (STAGING) return;
  if (DISABLE_CONVERSIONS) return;
  let event = CHECKOUT_PAYMENT_FAILED;
  switch (method) {
    case PaymentMethod.BOLETO: {
      event = CHECKOUT_RD_EVENTS['boleto-falha'];
      break;
    }
    case PaymentMethod.BOLETO_AND_CARD: {
      event = CHECKOUT_RD_EVENTS['boleto-e-cartao-falha'];
      break;
    }
    case PaymentMethod.CARD: {
      event = CHECKOUT_RD_EVENTS['cartao-falha'];
      break;
    }
    case PaymentMethod.PIX: {
      event = CHECKOUT_RD_EVENTS['pix-falha'];
      break;
    }
    case PaymentMethod.TWO_CARDS: {
      event = CHECKOUT_RD_EVENTS['dois-cartoes-falha'];
      break;
    }
  }
  sendToRD(email, event, { name, phone: studentPhone || phone, city, state, planAlias: plan.alias });
};

export const onPaymentRetry = (
  { email, name, phone, studentPhone }: Customer,
  { city, state }: Address,
  plan: Plan
): void => {
  if (STAGING) return;
  if (DISABLE_CONVERSIONS) return;
  sendToRD(email, CHECKOUT_PAYMENT_RETRY, { name, phone: studentPhone || phone, city, state, planAlias: plan.alias });
};
