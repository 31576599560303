// eslint-disable-next-line
// @ts-expect-error
import pagarme from 'pagarme/browser';

import { Address, Customer } from '../types';

type Validator = (a: string) => boolean;

const emailRegexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const validateEmail: Validator = email => emailRegexp.test(email);
export const validateString: Validator = string => !!string && !!string.length;
export const validateCPF: Validator = cpf => {
  if (!cpf) return false;
  if (cpf.length !== 11) return false;

  let add = 0;
  for (let i = 0; i < 9; i += 1) add += parseInt(cpf.charAt(i), 10) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9), 10)) return false;

  add = 0;
  for (let i = 0; i < 10; i += 1) add += parseInt(cpf.charAt(i), 10) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10), 10)) return false;

  return true;
};

export const validatePhone: Validator = phone => {
  const v = pagarme.validate({ ddd: phone.substring(0, 2), phone: phone.substring(2, 11) });
  return v.ddd && v.phone;
};
export const validateZipcode: Validator = zipcode => pagarme.validate({ zipcode }).zipcode;
export const validateNumber: Validator = number => /^\d+$/.test(number);

export const validateCardNumber: Validator = number =>
  // eslint-disable-next-line
  pagarme.validate({ card: { card_number: number } }).card.card_number;
export const validateCardCVV: Validator = cvv => /^\d{3,4}$/.test(cvv);
export const validateCardExpirationDate: Validator = date => {
  if (!date.length) return false;

  if (!/^\d{4}$/.test(date)) return false;
  const month = parseInt(date.substring(0, 2), 10);
  if (month < 1) return false;
  if (month > 12) return false;

  const year = parseInt(date.substring(2, 4), 10);
  if (year < 18) return false;

  return true;
};

export const validateCustomer = (customer: Customer): boolean => {
  if (!validateCPF(customer.cpf)) return false;
  if (!validatePhone(customer.phone)) return false;
  if (!validateString(customer.financierName)) return false;
  if (!validateEmail(customer.financierEmail)) return false;
  return true;
};

export const validateStudentCustomer = (customer: Customer): boolean => {
  if (!validateString(customer.name)) return false;
  if (!validateEmail(customer.email)) return false;
  if (!validatePhone(customer.studentPhone)) return false;
  return true;
};

export const validateAddress = (address: Address): boolean => {
  if (!validateZipcode(address.zipcode)) return false;
  if (!validateString(address.street)) return false;
  if (!validateString(address.city)) return false;
  if (!validateString(address.state)) return false;
  if (!validateString(address.neighborhood)) return false;
  if (!validateNumber(address.number)) return false;
  return true;
};
