// eslint-disable-next-line
// @ts-expect-error
import pagarme from 'pagarme';

import { PAGARME_KEY } from './env';
import { Address, CardInfo } from './types';

interface PagarmeClient {
  zipcodes: {
    find: ({ zipcode }: { zipcode: string }) => Promise<Address>;
  };
  security: {
    encrypt: ({
      // eslint-disable-next-line
      card_holder_name,
      // eslint-disable-next-line
      card_expiration_date,
      // eslint-disable-next-line
      card_number,
      // eslint-disable-next-line
      card_cvv,
    }: {
      card_holder_name: string;
      card_expiration_date: string;
      card_number: string;
      card_cvv: string;
    }) => Promise<string>;
  };
}

const pagarmeClient = (): Promise<PagarmeClient> =>
  // eslint-disable-next-line
  pagarme.client.connect({ encryption_key: PAGARME_KEY });

export const getCardHash = (card: CardInfo): Promise<string> =>
  pagarmeClient().then(client =>
    client.security.encrypt({
      // eslint-disable-next-line
      card_holder_name: card.name,
      // eslint-disable-next-line
      card_expiration_date: card.expirationDate,
      // eslint-disable-next-line
      card_number: card.number,
      // eslint-disable-next-line
      card_cvv: card.cvv,
    })
  );

export default pagarmeClient;
