import React, { useState, FormEvent } from 'react';

import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Checkbox from '@mui/material/Checkbox';
import { green } from '@mui/material/colors';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

interface InnerInputProps {
  valid: boolean;
}

const InnerInput = styled.input<InnerInputProps>`
  background-color: rgb(255, 255, 255);
  border: 1px solid ${(props): string => (props.valid ? 'rgb(204, 204, 204)' : '#ff270f')};
  box-sizing: border-box;
  box-shadow: none;
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
`;

// const Text = styled.span``;

// const ErroMsg = styled.span``;

interface InputProps {
  value: string;
  text?: string;
  validate: (s: string) => boolean;
  onChange: (s: string) => void;
  type?: string;
  pattern?: string;
  inputMode?: 'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined;
}

const Input: React.FC<InputProps> = ({ value, text, validate, onChange, type, pattern, inputMode }) => {
  const [valid, setValid] = useState<boolean>(true);

  return (
    <Container>
      <InnerInput
        inputMode={inputMode}
        pattern={pattern}
        value={value || ''}
        placeholder={text}
        onBlur={(e): void => setValid(validate(e.target.value))}
        onFocus={(): void => setValid(true)}
        onChange={(e): void => onChange(e.target.value)}
        valid={valid}
        type={type}
      />
    </Container>
  );
};

const useStyles = makeStyles({
  empty: {
    borderWidth: 2,
  },
  normal: {
    '& input:valid + fieldset': {
      borderColor: 'green',
      borderWidth: 2,
    },
    '& input:invalid + fieldset': {
      borderColor: 'red',
      borderWidth: 2,
    },
    '& input:valid:focus + fieldset': {
      padding: '4px !important', // override inline-style
    },
  },
});
interface InputStyledProps {
  inputMode?: 'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined;
  onBlur?: (s: string) => void;
  onChange: (s: string) => void;
  required?: boolean;
  text?: string;
  title: string;
  type?: string;
  validate?: (s: string) => boolean;
  value: string;
  onInvalidMessage?: string;
}

export const InputStyled: React.FC<InputStyledProps> = ({
  inputMode,
  onBlur,
  onChange,
  required,
  text,
  title,
  type,
  validate,
  value,
  onInvalidMessage,
}) => {
  const classes = useStyles(!!value);
  let className = '';
  if (required) {
    className = value ? classes.normal : classes.empty;
  }

  let onInvalid;
  if (onInvalidMessage) {
    onInvalid = (e: FormEvent): void => {
      const target = e.target as HTMLInputElement;
      if (target.setCustomValidity && typeof target.setCustomValidity === 'function')
        target.setCustomValidity(onInvalidMessage);
    };
  }

  return (
    <Container style={{ marginTop: 10 }}>
      <TextField
        className={className}
        error={!!value && value.length > 0 && !!validate && !validate(value)}
        inputMode={inputMode}
        label={title}
        onBlur={(e): void => {
          if (onBlur) onBlur(e.target.value);
        }}
        onChange={(e): void => onChange(e.target.value)}
        placeholder={text}
        required={required}
        type={type}
        value={value || ''}
        variant="outlined"
        onInvalid={onInvalid}
      />
    </Container>
  );
};

export const InputValidateOnChange: React.FC<InputProps> = ({ value, text, validate, onChange }) => (
  <Container>
    <OutlinedInput
      value={value || ''}
      title={text}
      onChange={(e): void => onChange(e.target.value)}
      error={!validate(value)}
      startAdornment={<InputAdornment position="start">R$</InputAdornment>}
    />
  </Container>
);

export default Input;

const GreenCheckbox = withStyles({
  root: {
    color: green[900],
    '&$checked': {
      color: green[900],
    },
  },
  checked: {},
})(Checkbox);

interface CheckBoxProps {
  checked: boolean;
  onChange: (b: boolean) => void;
  style?: React.CSSProperties;
}

export const CheckBox: React.FC<CheckBoxProps> = ({ checked, onChange, style }) => (
  <GreenCheckbox
    checked={checked || false}
    style={style}
    onChange={(e): void => {
      onChange(e.target.checked);
    }}
  />
);
