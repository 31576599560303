export interface Plan {
  type: string;
  classId: number;
  count: number;
  maxInstallments: number;
  alias: string;
  name: string;
  price: number;
  rating?: number;
  fullPrice: number;
  imageUrl: string;
  enableDiscount: boolean;
  payByPix: boolean;
  payByBoleto: boolean;
  payByCard: boolean;
  payByTwoCards: boolean;
  until: string;
  className?: string;
}

export interface Coupon {
  alias: string;
  discount: number;
  fullDiscount: number;
  payByPix: boolean;
  payByBoleto: boolean;
  payByCard: boolean;
  payByTwoCards: boolean;
  until: string;
  limited: boolean;
  remaining: string;
}

export interface CompletePrice {
  maxPrice: number;
  fullPrice: number;
  maxInstallments: number;
  monthlyPrice: number;
}

export interface Customer {
  name: string;
  email: string;
  phone: string;
  cpf: string;
  studentCpf: string;
  financierName: string;
  financierEmail: string;
  studentPhone: string;
}

export interface Address {
  zipcode: string;
  number: string;
  complement: string;
  street: string;
  neighborhood: string;
  city: string;
  state: string;
}

export enum PaymentMethod {
  BOLETO = 'BOLETO',
  CARD = 'CARD',
  TWO_CARDS = 'TWO_CARDS',
  BOLETO_AND_CARD = 'BOLETO_AND_CARD',
  PIX = 'PIX',
}

export interface CardInfo {
  name: string;
  number: string;
  cvv: string;
  expirationDate: string;
  installments: number;
  value: number;
}

export interface PaymentInfo {
  method: PaymentMethod;
  cards: CardInfo[];
}

export enum PaymentStatus {
  NO_PAYMENT,
  PIX_SUCCESS,
  PIX_FAIL,
  BOLETO_SUCCESS,
  BOLETO_FAIL,
  BOLETO_AND_CARD_SUCCESS,
  BOLETO_AND_CARD_SUCCESS_NO_STUDENT,
  BOLETO_AND_CARD_SUCCESS_NO_BOLETO,
  BOLETO_AND_CARD_FAIL,
  CARD_SUCCESS,
  CARD_SUCCESS_NO_STUDENT,
  CARD_FAIL,
  TWO_CARD_SUCCESS,
  TWO_CARD_SUCCESS_NO_STUDENT,
  TWO_CARD_SUCCESS_PAYMENT_FAIL,
  TWO_CARD_SUCCESS_PAYMENT_FAIL_NO_STUDENT,
  TWO_CARD_FAIL,
  REENROLLMENT_FAIL,
  ADVISOR_ELIGIBLE_FAIL,
  ADVISOR_BOLETO_SUCCESS,
  ADVISOR_CARD_SUCCESS,
  ADVISOR_CARD_SUCCESS_NO_ADVISOR,
  ADVISOR_BOLETO_AND_CARD_SUCCESS_NO_BOLETO,
  ADVISOR_BOLETO_AND_CARD_SUCCESS,
  ADVISOR_BOLETO_AND_CARD_SUCCESS_NO_ADVISOR,
  ADVISOR_TWO_CARD_SUCCESS,
  ADVISOR_TWO_CARD_SUCCESS_NO_ADVISOR,
  ADVISOR_TWO_CARD_SUCCESS_PAYMENT_FAIL,
  ADVISOR_TWO_CARD_SUCCESS_PAYMENT_FAIL_NO_ADVISOR,
  UPDATE_CARD_SUCCESS,
  UPDATE_CARD_FAIL,
}

export enum Step {
  CUSTOMER,
  PAYMENT,
  END,
  ADVISOR_ELIGIBLE_FAIL,
  REENROLLMENT_FAIL,
}

export interface PayMissingPayload {
  plan: Plan;
  afc?: Coupon;
  amount: number;
  payFull: boolean;
}

export interface CountApprovals {
  count: number;
  entranceExamName: string;
  undergraduateCourseName: string;
}
