import { Plan, Coupon } from '../types';

export const getMaxPrice = (plan: Plan): number => {
  if (plan.type === 'MONTHLY') return plan.price;
  return Math.max(plan.fullPrice, plan.price * plan.maxInstallments);
};

export const getTotalPrice = (plan: Plan, coupon?: Coupon): number => {
  if (!plan) return 0;
  if (plan.type === 'MONTHLY') return plan.price;
  if (!plan.enableDiscount) return plan.price * plan.maxInstallments;
  if (!coupon) return plan.price * plan.maxInstallments;
  const pricePerMonth = plan.price - coupon.discount;
  return pricePerMonth * plan.maxInstallments;
};

export const getFullPrice = (plan: Plan, coupon?: Coupon, withoutFullDiscount?: boolean): number => {
  if (withoutFullDiscount) return getTotalPrice(plan, coupon);
  if (!plan) return 0;
  if (plan.type === 'MONTHLY') return plan.price;
  if (!plan.enableDiscount) return plan.fullPrice;
  if (!coupon) return plan.fullPrice;
  return plan.fullPrice - coupon.fullDiscount;
};
