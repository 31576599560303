import React from 'react';
import styled from 'styled-components';

import { LOGO } from '../constants/logos';

const Spinner = styled.div`
  width: 100%;
  text-align: center;

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;

const Bounce = styled.div`
  width: 18px;
  height: 18px;
  background-color: rgba(27, 94, 32, 0.9);
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 3s infinite ease-in-out both;
  animation: sk-bouncedelay 3s infinite ease-in-out both;
`;

const Bounce1 = styled(Bounce)`
  -webkit-animation-delay: 0;
  animation-delay: 0;
`;

const Bounce2 = styled(Bounce)`
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
`;

const Bounce3 = styled(Bounce)`
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
`;

export const Dots: React.FC = () => (
  <Spinner>
    <Bounce1 />
    <Bounce2 />
    <Bounce3 />
  </Spinner>
);

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const Center = styled.div`
  margin: auto;
  text-align: center;
`;

const Loader: React.FC = () => (
  <Container>
    <Center>
      <img alt="kuadro" src={LOGO} width="300px" />
      <Dots />
    </Center>
  </Container>
);

export default Loader;
