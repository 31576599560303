/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { DateTime } from 'luxon';

import { BASE_URL, STAGING, DISABLE_CONVERSIONS } from './env';
import { getCardHash } from './pagarme';
import { getFullPrice, getTotalPrice } from './utils/price';
import { Plan, Coupon, PaymentMethod, Customer, Address, PaymentInfo, PayMissingPayload } from './types';

declare global {
  interface Window {
    dataLayer: {
      push: (r: Record<string, unknown>) => void;
    };
  }
}

const api = axios.create({ baseURL: BASE_URL });

export const getPlanAndCoupon = (planCode: string, afcCode?: string): Promise<[Plan, Coupon?]> => {
  if (afcCode)
    return api.get(`/api/public/plan/afc/${planCode}?afcCode=${afcCode}`).then(resp => {
      if (resp.data.afc && resp.data.afc.alias) return [resp.data.plan, resp.data.afc];
      return [resp.data.plan];
    });
  return api.get(`/api/public/plan/afc/${planCode}`).then(resp => [resp.data.plan]);
};

export const sendToRD = (email: string, event: string, payload?: Record<string, unknown>): void => {
  if (STAGING) return;
  if (DISABLE_CONVERSIONS) return;
  api({
    url: `/api/public/conversion`,
    method: 'POST',
    data: {
      email,
      event,
      ...payload,
    },
  }).catch(window.console.error);
};

const getPaymentMethod = (method: PaymentMethod): string => {
  switch (method) {
    case PaymentMethod.PIX:
      return 'pix';
    case PaymentMethod.BOLETO:
      return 'bank_slip';
    case PaymentMethod.CARD:
    case PaymentMethod.TWO_CARDS:
      return 'credit_card';
    case PaymentMethod.BOLETO_AND_CARD:
      return 'boleto-and-card';
    default:
      return '';
  }
};

export const informGoogleAds = (payment: PaymentInfo, plan: Plan, coupon?: Coupon): void => {
  if (STAGING) return;
  if (DISABLE_CONVERSIONS) return;
  const fullPrice = getFullPrice(plan, coupon);
  const totalPrice = getTotalPrice(plan, coupon);
  let transactionTotal = fullPrice;
  if (![PaymentMethod.BOLETO, PaymentMethod.PIX].includes(payment.method)) {
    const installments = payment.cards.reduce((acc, cur) => acc * cur.installments, 1);
    if (installments !== 1) transactionTotal = totalPrice;
  }
  window.dataLayer.push({
    event: 'transactionSuccessful',
    transactionId: Math.floor(DateTime.local().valueOf() / 1000),
    transactionTotal: transactionTotal / 100,
    courseChosen: plan.alias,
    coursePackage: plan.type,
    paymentMethod: getPaymentMethod(payment.method),
  });
};

const getUrl = (plan: Plan, paymentType: string): string =>
  `/api/public/pay${plan.type.toLowerCase()}by${paymentType.toLowerCase()}`;

type Process = (
  plan: Plan,
  coupon: Coupon | undefined,
  customer: Customer,
  address: Address,
  payment: PaymentInfo,
  origin: string
) => Promise<{
  paymentSuccess: boolean;
  advisorAttributed: boolean;
  studentCreated: boolean;
  url: string;
  pixQRCode: string;
}>;

const processPayByPix: Process = (plan, coupon, customer, address, origin) =>
  api
    .post(getUrl(plan, 'pix'), {
      planAlias: plan.alias,
      afcAlias: coupon && coupon.alias,
      customer,
      address,
      origin,
    })
    .then(resp => resp.data);

const processPayByBoleto: Process = (plan, coupon, customer, address, origin) =>
  api
    .post(getUrl(plan, 'boleto'), {
      planAlias: plan.alias,
      afcAlias: coupon && coupon.alias,
      customer,
      address,
      origin,
    })
    .then(resp => resp.data);

const processPayByBoletoAndCard: Process = (plan, coupon, customer, address, payment, origin) =>
  getCardHash(payment.cards[0]).then(cardHash =>
    api
      .post(getUrl(plan, 'boletoandcard'), {
        planAlias: plan.alias,
        afcAlias: coupon && coupon.alias,
        customer,
        address,
        payment: { priceOnCard: payment.cards[0].value, installments: `${payment.cards[0].installments}`, cardHash },
        origin,
      })
      .then(resp => resp.data)
  );

const processPayByCard: Process = (plan, coupon, customer, address, payment, origin) =>
  getCardHash(payment.cards[0]).then(cardHash =>
    api
      .post(getUrl(plan, 'card'), {
        planAlias: plan.alias,
        afcAlias: coupon && coupon.alias,
        customer,
        address,
        payment: { installments: `${payment.cards[0].installments}`, cardHash },
        origin,
      })
      .then(resp => resp.data)
  );

const processPayByTwoCards: Process = (plan, coupon, customer, address, payment, origin) => {
  const { cards } = payment;
  return Promise.all([getCardHash(cards[0]), getCardHash(cards[1])])
    .then(([firstHash, secondHash]) =>
      api.post(getUrl(plan, 'twocards'), {
        planAlias: plan.alias,
        afcAlias: coupon && coupon.alias,
        customer,
        address,
        payment: {
          priceOnFirst: cards[0].value,
          first: {
            installments: `${cards[0].installments}`,
            cardHash: firstHash,
          },
          second: {
            installments: `${cards[1].installments}`,
            cardHash: secondHash,
          },
        },
        origin,
      })
    )
    .then(resp => resp.data);
};

export const processPay: Process = (plan, coupon, customer, address, payment, origin) => {
  switch (payment.method) {
    case PaymentMethod.PIX:
      return processPayByPix(plan, coupon, customer, address, payment, origin);
    case PaymentMethod.BOLETO:
      return processPayByBoleto(plan, coupon, customer, address, payment, origin);
    case PaymentMethod.CARD:
      return processPayByCard(plan, coupon, customer, address, payment, origin);
    case PaymentMethod.TWO_CARDS:
      return processPayByTwoCards(plan, coupon, customer, address, payment, origin);
    case PaymentMethod.BOLETO_AND_CARD:
      return processPayByBoletoAndCard(plan, coupon, customer, address, payment, origin);
    default:
      return processPayByBoleto(plan, coupon, customer, address, payment, origin);
  }
};

export const getMissingPayment = (enrollmentId: string): Promise<PayMissingPayload> =>
  api.get(`/api/public/missingpayment?enrollmentId=${enrollmentId}`).then(resp => {
    if (!resp.data.afc.discount) {
      return { plan: resp.data.plan, amount: resp.data.amount, payFull: resp.data.payFull };
    }
    return resp.data;
  });

export const processMissingPay = (enrollmentId: string, payment: PaymentInfo): Promise<string> =>
  getCardHash(payment.cards[0]).then(cardHash =>
    api
      .post(`/api/public/paymissing`, {
        enrollmentId,
        payment: {
          installments: `${payment.cards[0].installments}`,
          cardHash,
        },
      })
      .then(resp => resp.data)
  );

export const reenrollmentEligible = (email: string): Promise<boolean> =>
  api.get(`/api/public/reenrollmenteligible?email=${encodeURIComponent(email)}`).then(resp => resp.data.eligible);

export const advisorEligible = (email: string): Promise<boolean> =>
  api.get(`/api/public/tutoring/eligible/${encodeURIComponent(email)}`).then(resp => resp.data.isEligible);

export const updateCard = (enrollmentId: string, payment: PaymentInfo): Promise<boolean> =>
  getCardHash(payment.cards[0]).then(cardHash =>
    api
      .post(`/api/public/updatecard`, {
        enrollmentId,
        payment: {
          cardHash,
        },
      })
      .then(resp => resp.data)
  );

export const sendProblemReport = async (report: string): Promise<boolean> => {
  if (STAGING) return true;
  if (DISABLE_CONVERSIONS) return true;
  return api
    .post(`/api/public/report`, { report })
    .then(() => true)
    .catch(window.console.error)
    .then(() => false);
};
