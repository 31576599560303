import React from 'react';

import { makeCDNURL, makeCheckoutCDNURL } from '../images-cdn';

interface ImageProps {
  src: string;
  width: string;
  height?: string;
}
export const CDNImage: React.FC<ImageProps> = ({ src, width, height }) => (
  <img alt={src} style={{ width, height }} src={makeCDNURL(src)} />
);

export const Image: React.FC<ImageProps> = ({ src, width, height }) => (
  <img alt={src} style={{ width, height }} src={src} />
);

export const CheckoutCDNImage: React.FC<ImageProps> = ({ src, width, height }) => (
  <img alt={src} style={{ width, height }} src={makeCheckoutCDNURL(src)} />
);
