import React, { useState } from 'react';

import styled from 'styled-components';

import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';

import PaymentMethodCMP from './PaymentMethod';
import Card from './Card';
import { Loader, SaveButton } from './Button';
import { InputValidateOnChange } from './Input';

import { maskPrice, unmaskPrice } from '../utils/format';
import { getFullPrice, getTotalPrice } from '../utils/price';

import { Plan, Coupon, PaymentInfo, PaymentMethod } from '../types';

export const Button = styled.button`
  width: fit-content;
  text-decoration: none;
  color: white;
  background: linear-gradient(to bottom, rgba(124, 208, 98, 1) 0%, #6fbb59 100%);
  padding: 10px 30px;
  display: inline-block;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.21);
  border-bottom: 4px solid rgba(0, 0, 0, 0.21);
  border-radius: 4px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  :active {
    background: #72b08e;
  }
  :disabled {
    cursor: default;
    background: rgb(221, 221, 221);
  }
  margin-right: 15px;
`;

const BoletoAndCardContainer = styled.div`
  margin: 16px;
  width: 50vw;
  @media (max-width: 480px) {
    width: 80vw;
  }
`;

const GridItem = withStyles({
  root: {
    maxWidth: '95%',
  },
})(Grid);

const getValueOnBoleto = (payment: PaymentInfo, plan: Plan, coupon?: Coupon): number => {
  const [card] = payment.cards;
  const fullPrice = getFullPrice(plan, coupon);
  const totalPrice = getTotalPrice(plan, coupon);
  if (card.installments === 1) return fullPrice - card.value;
  return totalPrice - card.value;
};

const getPaymentFromBoletoValue = (value: number, payment: PaymentInfo, plan: Plan, coupon?: Coupon): PaymentInfo => {
  const [card] = payment.cards;
  const fullPrice = getFullPrice(plan, coupon);
  const totalPrice = getTotalPrice(plan, coupon);
  const valueOnCard = card.installments === 1 ? fullPrice - value : totalPrice - value;
  return { ...payment, cards: [{ ...card, value: valueOnCard }] };
};

interface PaymentProps {
  plan: Plan;
  coupon?: Coupon;
  payment: PaymentInfo;
  enableNext: boolean;
  enable: boolean;
  onChange: (p: PaymentInfo) => void;
  onNext: () => Promise<unknown>;
}

const PaymentCMP: React.FC<PaymentProps> = ({ plan, coupon, payment, enableNext, enable, onChange, onNext }) => {
  const [paying, setPaying] = useState<boolean>(false);

  const pay = (): void => {
    if (!enableNext) return;
    setPaying(true);
    onNext().finally(() => setPaying(false));
  };

  const setSecondCardValue = (v: number): void => {
    const p = { ...payment };
    const {
      cards: [{ installments: installmentsOnFirst }, { installments: installmentsOnSecond }],
    } = payment;
    const fullPrice = getFullPrice(plan, coupon);
    const totalPrice = getTotalPrice(plan, coupon);
    const useFull = installmentsOnFirst === 1 && installmentsOnSecond === 1;
    p.cards[0].value = useFull ? fullPrice - v : totalPrice - v;
    p.cards[1].value = v;
    onChange(p);
  };

  switch (payment.method) {
    case PaymentMethod.BOLETO:
      return (
        <PaymentMethodCMP
          pay={pay}
          paying={paying}
          plan={plan}
          coupon={coupon}
          payment={payment}
          enable={enable}
          onChange={onChange}
          enableNext={enableNext}
          onChangeSecondCardValue={setSecondCardValue}
        />
      );

    case PaymentMethod.CARD:
      return (
        <PaymentMethodCMP
          pay={pay}
          paying={paying}
          plan={plan}
          coupon={coupon}
          payment={payment}
          enable={enable}
          onChange={onChange}
          enableNext={enableNext}
        />
      );

    case PaymentMethod.TWO_CARDS:
      return (
        <PaymentMethodCMP
          pay={pay}
          paying={paying}
          plan={plan}
          coupon={coupon}
          payment={payment}
          enable={enable}
          onChange={onChange}
          enableNext={enableNext}
          onChangeSecondCardValue={setSecondCardValue}
        />
      );

    case PaymentMethod.BOLETO_AND_CARD:
      return (
        <BoletoAndCardContainer>
          <Grid container spacing={2}>
            <GridItem item xs={12}>
              <span style={{ fontWeight: 700 }}>Boleto</span>
            </GridItem>
            <GridItem item xs={12}>
              <InputValidateOnChange
                inputMode="numeric"
                key="input_boleto"
                value={maskPrice(getValueOnBoleto(payment, plan, coupon))}
                onChange={(v): void => onChange(getPaymentFromBoletoValue(unmaskPrice(v), payment, plan, coupon))}
                validate={(): boolean => true}
              />
            </GridItem>
          </Grid>
          <Card
            payment={payment}
            paying={paying}
            plan={plan}
            coupon={coupon}
            enableNext={enableNext}
            index={0}
            onChangeCard={(c): void => onChange({ ...payment, cards: [c] })}
          />
          <Grid container spacing={2}>
            <GridItem item xs={12}>
              <SaveButton disabled={!enableNext || paying} onClick={pay}>
                {paying ? <Loader /> : 'Comprar Agora'}
              </SaveButton>
            </GridItem>
          </Grid>
        </BoletoAndCardContainer>
      );

    default:
      return (
        <PaymentMethodCMP
          pay={pay}
          paying={paying}
          plan={plan}
          coupon={coupon}
          enable={enable}
          payment={payment}
          onChange={onChange}
          enableNext={enableNext}
          onChangeSecondCardValue={setSecondCardValue}
        />
      );
  }
};

export default PaymentCMP;
