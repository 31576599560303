import React, { useState } from 'react';

import styled from 'styled-components';

import useMediaQuery from '@mui/material/useMediaQuery';

import makeStyles from '@mui/styles/makeStyles';
import MuiDialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';

import { CheckoutCDNImage } from './Image';

const Container = styled.div`
  align-items: center;
  border: none;
  box-sizing: border-box;
  display: flex;
  height: auto;
  justify-content: space-between;
  margin-bottom: 60px;
  margin-top: 10px;
  position: relative;
  width: 100%;
`;

const AvailableClassesContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin-left: 20px;
  max-width: 831px;
  overflow: auto;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  @media (max-width: 480px) {
    max-width: 600px;
    overflow: auto;
  }
`;

const Hr = styled.div`
  background-image: linear-gradient(90deg, transparent, #d3d3d3, transparent);
  height: 1px;
  margin-bottom: 20px;
  width: 100%;
`;

const Span = styled.span`
  color: #3c4043;
  font-size: 1.5rem;
  @media (max-width: 800px) {
    font-size: 1.25rem;
  }
  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const useStyles = makeStyles({
  card: {
    borderRadius: '18px',
    flex: '0 0 auto',
    height: 150,
    margin: '10px 33px 10px 0',
    maxWidth: 255,
    minWidth: 255,
    scrollSnapAlign: 'start',
  },
  dialog: {
    width: 'auto',
  },
  dialogPaper: {
    margin: 24,
  },
  dialogContentDesktop: {
    background: 'black',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    paddingBottom: 16,
    width: 900,
  },
  dialogContentMobile: {
    background: 'black',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 16,
  },
  media: {
    backgroundColor: 'transparent',
    height: 150,
  },
});

const histories = [
  {
    youtubeCode: 'DLHhIW8ASBs',
    image: '/aprovados/gabriel-eduardo.png',
  },
  {
    youtubeCode: '90tf8p8WBQo',
    image: '/aprovados/pedro-puglia.png',
  },
  {
    youtubeCode: 'scBEcO0QpUM',
    image: '/aprovados/rafaela-arsenio.png',
  },
  {
    youtubeCode: 'hsKLb4MFs2w',
    image: '/aprovados/guilherme-yanq.png',
  },
  {
    youtubeCode: 'tU2RxKV6kbY',
    image: '/aprovados/bianca-campos.png',
  },
  {
    youtubeCode: 'fennlf7uB_o',
    image: '/aprovados/sayuri-esaki.png',
  },
  {
    youtubeCode: 'mAEx4f-E59Q',
    image: '/aprovados/joao-sendrete.png',
  },
];

interface DialogTitleProps {
  onClose: () => void;
}
const DialogTitle: React.FC<DialogTitleProps> = ({ onClose }) => (
  <MuiDialogTitle style={{ color: '#ffffff', backgroundColor: '#000000' }}>
    Depoimento
    <IconButton onClick={onClose} style={{ position: 'absolute', top: 10, right: 10 }} size="large">
      <CloseIcon style={{ color: '#fff' }} />
    </IconButton>
  </MuiDialogTitle>
);

const disabled = true;
const HistoriesCMP: React.FC = () => {
  const classes = useStyles();
  const isPhoneDevice = useMediaQuery('(max-width:480px)');
  const [open, setOpen] = useState<boolean>(false);
  const [ytCode, setYtCode] = useState<string>('');

  if (disabled) return null;
  const src = `https://www.youtube.com/embed/${ytCode}`;

  return (
    <div>
      <Hr />
      <div style={{ maxWidth: 675, marginLeft: 20 }}>
        <Span>
          Vários alunos, <strong>como você, já passaram nas melhores Universidades Públicas </strong> com o Método
          Kuadro de aprovação.
        </Span>
      </div>
      <Container>
        <AvailableClassesContainer>
          {histories.map(history => (
            <Card
              key={history.image}
              className={classes.card}
              onClick={(): void => {
                setOpen(true);
                setYtCode(history.youtubeCode);
              }}
            >
              <CardActionArea>
                <CheckoutCDNImage src={history.image} width="auto" />
              </CardActionArea>
            </Card>
          ))}
        </AvailableClassesContainer>
        <Dialog
          className={classes.dialog}
          classes={{ paper: classes.dialogPaper }}
          maxWidth="xl"
          onClose={(): void => setOpen(false)}
          open={open}
        >
          <DialogTitle onClose={(): void => setOpen(false)} />
          <DialogContent className={isPhoneDevice ? classes.dialogContentMobile : classes.dialogContentDesktop}>
            <iframe
              allow="autoplay; encrypted-media"
              allowFullScreen
              frameBorder="0"
              height={isPhoneDevice ? '180' : '480'}
              src={src}
              title="youtubeModal"
              width={isPhoneDevice ? '310' : '860'}
            />
          </DialogContent>
        </Dialog>
      </Container>
    </div>
  );
};

export default HistoriesCMP;
