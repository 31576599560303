import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import useMediaQuery from '@mui/material/useMediaQuery';

import { Step, Plan, Coupon, Customer, Address, PaymentMethod, PaymentInfo, PaymentStatus, CardInfo } from '../types';

import FinancierCustomerCMP from './FinancierCustomer';
import StudentCustomerCMP from './StudentCustomer';
import HistoriesCMP from './Histories';
import PaymentCMP from './Payment';
import End from './End';
import Title from './Title';
import Steps from './Steps';
import { CDNImage } from './Image';
import { SaveButton } from './Button';

import { advisorEligible } from '../api';

import { EndStatusTranslate, CheckoutSteps } from '../constants/app';
import { KUADRO_GREEN } from '../constants/styles';
import { onPageView, onCustomerBeginWithData, onCheckoutBegin, onPaymentRetry } from '../utils/marketing';
import {
  validateAddress,
  validateCardCVV,
  validateCardExpirationDate,
  validateCardNumber,
  validateCustomer,
  validateString,
  validateStudentCustomer,
} from '../utils/validators';
import { getFullPrice, getTotalPrice } from '../utils/price';
import { unmaskPhone } from '../utils/format';
import useCache from '../utils/useCache';

export const Canvas = styled.div`
  display: flex;
  background-color: white;
  box-sizing: border-box;
  flex-flow: row-reverse;
  margin: 30px 0 80px;
  @media (max-width: 1100px) {
    display: block;
    margin: 10px 15px;
  }
`;

const Header = styled.div`
  background-color: ${KUADRO_GREEN};
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  height: auto;
  justify-content: center;
  left: 0;
  padding: 15px 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;
  @media (max-width: 1100px) {
    height: 60px;
    padding: 0;
  }
`;

const Holder = styled.div`
  display: flex;
  max-width: 1220px;
  width: inherit;
  @media (max-width: 1100px) {
    max-width: 320px;
  }
`;

const TextHeader = styled.div`
  width: 81%;
  font-weight: 400;
  margin: auto 10px;
  color: #ffffff;
  display: flex;
  align-content: center;
  font-size: 24px;
  @media (max-width: 1100px) {
    font-size: 16px;
  }
`;

const Footer = styled.div`
  background-color: ${KUADRO_GREEN};
  bottom: 0;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);
  color: #ffffff;
  display: flex;
  height: auto;
  justify-content: center;
  left: 0;
  padding: 15px 0;
  position: absolute;
  width: 100vw;
  z-index: 10;
  @media (max-width: 1100px) {
    display: none;
    height: 60px;
    padding: 0;
  }
`;

const PaymentContainer = styled.div`
  width: 50vw;
  @media (max-width: 1100px) {
    min-width: 315px;
    margin-left: 10px;
  }
`;

const getDefaultPaymentMethod = (plan: Plan, coupon?: Coupon, forceBoletoAndCard?: boolean): PaymentInfo => {
  const value = getFullPrice(plan, coupon);
  if (forceBoletoAndCard)
    return {
      method: PaymentMethod.BOLETO_AND_CARD,
      cards: [
        {
          name: '',
          number: '',
          cvv: '',
          expirationDate: '',
          installments: 1,
          value: value / 2,
        },
      ],
    };
  if (plan.payByCard) {
    if (!coupon || coupon.payByCard) {
      return {
        method: PaymentMethod.CARD,
        cards: [
          {
            name: '',
            number: '',
            cvv: '',
            expirationDate: '',
            installments: 1,
            value,
          },
        ],
      };
    }
  }
  if (plan.payByTwoCards) {
    if (!coupon || coupon.payByTwoCards) {
      return {
        method: PaymentMethod.TWO_CARDS,
        cards: [
          {
            name: '',
            number: '',
            cvv: '',
            expirationDate: '',
            installments: 1,
            value: value / 2,
          },
          {
            name: '',
            number: '',
            cvv: '',
            expirationDate: '',
            installments: 1,
            value: value - value / 2,
          },
        ],
      };
    }
  }
  if (plan.payByPix) {
    if (!coupon || coupon.payByPix) {
      return {
        method: PaymentMethod.PIX,
        cards: [],
      };
    }
  }
  return {
    method: PaymentMethod.BOLETO,
    cards: [],
  };
};

const getPaymentMethod = (
  plan: Plan,
  coupon?: Coupon,
  forceBoletoAndCard?: boolean,
  payment?: PaymentInfo
): PaymentInfo => {
  const value = getFullPrice(plan, coupon);
  const floorValue = Math.floor(value / 2);

  if (!payment) return getDefaultPaymentMethod(plan, coupon, forceBoletoAndCard);

  switch (payment.method) {
    case PaymentMethod.PIX:
      if (!plan.payByPix)
        if (!coupon || !coupon.payByPix) return getDefaultPaymentMethod(plan, coupon, forceBoletoAndCard);
      if (plan.payByPix) return payment;
      break;
    case PaymentMethod.BOLETO:
      if (!plan.payByBoleto)
        if (!coupon || !coupon.payByBoleto) return getDefaultPaymentMethod(plan, coupon, forceBoletoAndCard);
      if (plan.payByBoleto) return payment;
      break;
    case PaymentMethod.CARD:
      if (!plan.payByCard)
        if (!coupon || !coupon.payByCard) return getDefaultPaymentMethod(plan, coupon, forceBoletoAndCard);
      if (plan.payByCard) return payment;
      break;
    case PaymentMethod.TWO_CARDS:
      if (!plan.payByTwoCards)
        if (!coupon || !coupon.payByCard) return getDefaultPaymentMethod(plan, coupon, forceBoletoAndCard);
      if (plan.payByTwoCards)
        return {
          method: PaymentMethod.TWO_CARDS,
          cards: [
            {
              name: payment.cards[0].name,
              number: payment.cards[0].number,
              cvv: payment.cards[0].cvv,
              expirationDate: payment.cards[0].expirationDate,
              installments: payment.cards[0].installments,
              value: floorValue,
            },
            {
              name: payment.cards[1].name,
              number: payment.cards[1].number,
              cvv: payment.cards[1].cvv,
              expirationDate: payment.cards[1].expirationDate,
              installments: payment.cards[1].installments,
              value: value - floorValue,
            },
          ],
        };
      break;
    case PaymentMethod.BOLETO_AND_CARD:
      if (forceBoletoAndCard)
        return {
          method: PaymentMethod.BOLETO_AND_CARD,
          cards: [
            {
              name: payment.cards[0].name,
              number: payment.cards[0].number,
              cvv: payment.cards[0].cvv,
              expirationDate: payment.cards[0].expirationDate,
              installments: payment.cards[0].installments,
              value: floorValue,
            },
          ],
        };
      break;
    default:
  }

  return getDefaultPaymentMethod(plan, coupon, forceBoletoAndCard);
};

const validateCard = (card: CardInfo): boolean => {
  if (+card.value < 100) return false;
  if (!validateString(card.name)) return false;
  if (Number.isNaN(card.installments)) return false;
  if (Number.isNaN(card.value)) return false;
  if (!validateCardExpirationDate(card.expirationDate)) return false;
  if (!validateCardNumber(card.number)) return false;
  if (!validateCardCVV(card.cvv)) return false;
  return true;
};

const validatePayment = (payment: PaymentInfo, plan: Plan, coupon?: Coupon, forceBoletoAndCard?: boolean): boolean => {
  if (payment.method === PaymentMethod.PIX) {
    if (!plan.payByPix) return false;
    if (!coupon) return true;
    return coupon.payByPix;
  }

  if (payment.method === PaymentMethod.BOLETO) {
    if (!plan.payByBoleto) return false;
    if (!coupon) return true;
    return coupon.payByBoleto;
  }

  if (forceBoletoAndCard && payment.method !== PaymentMethod.BOLETO_AND_CARD) return false;

  if (!payment.cards.map(validateCard).reduce((acc, cur) => acc && cur)) return false;

  if (payment.method === PaymentMethod.CARD) {
    if (!plan.payByCard) return false;
    if (coupon && !coupon.payByCard) return false;
  }

  const fullPrice = getFullPrice(plan, coupon);
  const totalPrice = getTotalPrice(plan, coupon);

  if (payment.method === PaymentMethod.TWO_CARDS) {
    if (!plan.payByTwoCards) return false;
    if (coupon && !coupon.payByTwoCards) return false;
    const [{ value, installments }, { installments: installmentsOnSecond }] = payment.cards;
    const price = installments === 1 && installmentsOnSecond === 1 ? fullPrice : totalPrice;
    const valueOnSecond = price - value;
    if (value > price || value < 9999) return false;
    if (valueOnSecond > price || valueOnSecond < 9999) return false;
  }

  if (payment.method === PaymentMethod.BOLETO_AND_CARD) {
    const { value, installments } = payment.cards[0];
    const price = installments === 1 ? fullPrice : totalPrice;
    const valueOnBoleto = price - value;
    if (value > price || value < 9999) return false;
    if (valueOnBoleto > price || valueOnBoleto < 9999) return false;
  }
  return true;
};

export enum State {
  INFO,
  SUCCESS,
  SUCCESS_WAIT,
  PARTIAL_SUCCESS,
  WAITING_PAYMENT,
  ERROR,
}

const ContainerDisclaimer = styled.div`
  padding: 10px;
  font-size: 0.8em;
`;

const AdvisorDisclaimer: React.FC = () => (
  <ContainerDisclaimer>Esse plano só pode ser comprado por quem já é aluno.</ContainerDisclaimer>
);

const DEFAULT_CUSTOMER: Customer = {
  name: '',
  email: '',
  phone: '',
  cpf: '',
  studentCpf: '',
  financierName: '',
  financierEmail: '',
  studentPhone: '',
};

const DEFAULT_ADDRESS: Address = {
  zipcode: '',
  number: '',
  complement: '',
  street: '',
  neighborhood: '',
  city: '',
  state: '',
};

interface CheckoutProps {
  countApprovals: number;
  plan: Plan;
  coupon: Coupon | undefined;
  forceBoletoAndCard?: boolean;
  status: PaymentStatus;
  onPay: (c: Customer, a: Address, p: PaymentInfo, o: string) => Promise<string>;
  applyCoupon: (s: string) => Promise<boolean>;
}

const Checkout: React.FC<CheckoutProps> = ({
  countApprovals,
  plan,
  coupon,
  forceBoletoAndCard,
  status,
  onPay,
  applyCoupon,
}) => {
  const history = useHistory();
  const [origin, setOrigin] = useState<string>('');
  const [step, setStep] = useState<Step>(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('p')) {
      return Step.PAYMENT;
    }
    return Step.CUSTOMER;
  });
  const [cacheBoletoUrl, setBoletoUrl] = useCache<string>('boletoUrl', c => c || '');
  const [cachePixQRCode, setPixQRCode] = useCache<string>('pixQRCode', c => c || '');
  const [cacheCustomer, setCustomer] = useCache<Customer>('customer', c => c || DEFAULT_CUSTOMER);
  const [cacheAddress, setAddress] = useCache<Address>('address', c => c || DEFAULT_ADDRESS);
  const [cachePayment, setPayment] = useCache<PaymentInfo>(
    'payment',
    c => c || getDefaultPaymentMethod(plan, coupon, forceBoletoAndCard)
  );
  const [cacheCheckedToU, setCheckedToU] = useCache<boolean>('checkedToU', c => c || false);

  const boletoUrl = cacheBoletoUrl as string;
  const pixQRCode = cachePixQRCode as string;
  const customer = cacheCustomer as Customer;
  const address = cacheAddress as Address;
  const payment = cachePayment as PaymentInfo;
  const checkedToU = cacheCheckedToU as boolean;

  const isAdvisor = plan.type === 'ADVISOR';
  const checkAdvisorEligible = (email: string): void => {
    if (isAdvisor)
      advisorEligible(email).then(isEligible => {
        if (!isEligible) setStep(Step.ADVISOR_ELIGIBLE_FAIL);
      });
  };

  useEffect(() => {
    onCheckoutBegin();
    const params = new URLSearchParams(window.location.search);
    const o = params.get('origin');
    const name = params.get('name');
    const email = params.get('email');
    const phone = params.get('phone');
    const c: Partial<Customer> = {};
    if (o) {
      setOrigin(o);
      params.delete('origin');
    }
    if (name) {
      c.name = name;
      params.delete('name');
    }
    if (email) {
      c.email = email;
      onCustomerBeginWithData({ ...customer, ...c }, plan.alias);
      checkAdvisorEligible(email);
      params.delete('email');
    }
    if (phone) {
      c.studentPhone = unmaskPhone(phone);
      params.delete('phone');
    }
    setCustomer({ ...customer, ...c });
    const searchParams = params.toString();
    history.push(window.location.pathname + (searchParams ? '?' + searchParams : ''));
    setTimeout(() => {
      onPageView();
    }, 300);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPayment(getPaymentMethod(plan, coupon, forceBoletoAndCard, payment));
    // eslint-disable-next-line
  }, [coupon, forceBoletoAndCard, plan]);

  const validCustomer = useMemo(() => validateCustomer(customer) && checkedToU, [customer, checkedToU]);
  const validStudentCustomer = useMemo(() => validateStudentCustomer(customer), [customer]);
  const validAddress = useMemo(() => validateAddress(address), [address]);
  const validPayment = useMemo(
    () => validatePayment(payment, plan, coupon, forceBoletoAndCard),
    [payment, plan, coupon, forceBoletoAndCard]
  );
  const enablePay = checkedToU && validStudentCustomer && validCustomer && validAddress && validPayment;

  const isPhoneDevice = useMediaQuery('(max-width:1100px)');

  const pay = (): Promise<void> => {
    if (!enablePay) {
      Swal.fire({
        icon: 'warning',
        title: 'Por favor, confira os dados.',
        text: 'Alguns dados estão ausentes ou incorretos, confira também se você aceitou os Termos de Uso.',
      });
      return Promise.resolve();
    }
    return onPay(customer, address, payment, origin)
      .then(resp => {
        if (resp) {
          if (PaymentMethod.BOLETO === payment.method || PaymentMethod.BOLETO_AND_CARD === payment.method) {
            setBoletoUrl(resp);
            window.open(resp, '_blank');
          } else if (PaymentMethod.PIX === payment.method) {
            setPixQRCode(resp);
          }
        }
      })
      .finally(() => setStep(Step.END));
  };

  const retry = (): void => {
    setStep(Step.CUSTOMER);
    onPaymentRetry(customer, address, plan);
  };

  if (step === Step.END) {
    const endStatus = EndStatusTranslate[status];
    const searchParams = new URLSearchParams();
    if (forceBoletoAndCard) searchParams.set('f', '1');
    if (isAdvisor) searchParams.set('t', '1');
    const params = searchParams.toString();
    const to = `/finalizado/${endStatus}/${plan.alias}${coupon ? `/${coupon.alias}` : ''}${params ? `?${params}` : ''}`;
    return <Redirect to={to} />;
  }

  if (step === Step.ADVISOR_ELIGIBLE_FAIL)
    return (
      <Canvas>
        <PaymentContainer>
          <div style={{ display: 'block', width: '100%' }}>
            <End
              status={PaymentStatus.ADVISOR_ELIGIBLE_FAIL}
              boletoUrl={boletoUrl}
              pixQRCode={pixQRCode}
              onRetry={retry}
              email={customer.email}
            />
          </div>
        </PaymentContainer>
      </Canvas>
    );

  if (step === Step.REENROLLMENT_FAIL)
    return (
      <Canvas>
        <PaymentContainer>
          <div style={{ display: 'block', width: '100%' }}>
            <End
              status={PaymentStatus.REENROLLMENT_FAIL}
              boletoUrl={boletoUrl}
              pixQRCode={pixQRCode}
              onRetry={retry}
              email={customer.email}
            />
          </div>
        </PaymentContainer>
      </Canvas>
    );

  const handleRedirect = (nextStep: CheckoutSteps): void => {
    const pathnames = window.location.pathname.split('/').filter(el => el);
    const currentStep = pathnames[pathnames.length - 1];
    if (!(currentStep in CheckoutSteps)) {
      pathnames.push(nextStep);
    } else {
      pathnames[pathnames.length - 1] = nextStep;
    }
    history.push('/' + pathnames.join('/') + window.location.search);
  };

  const handleCustomerNext = (): void => {
    handleRedirect(CheckoutSteps.payment);
    setStep(Step.PAYMENT);
  };

  const handlePaymentBack = (): void => {
    handleRedirect(CheckoutSteps.lead);
    setStep(Step.CUSTOMER);
  };

  return (
    <div>
      <Header>
        <Holder>
          <div style={{ width: '20%', justifyContent: 'center', display: 'flex' }}>
            <CDNImage src="logokuadro.svg" width={isPhoneDevice ? '48px' : '76px'} />
          </div>
          {!isPhoneDevice ? (
            <TextHeader>
              <div>
                <p style={{ margin: 'auto' }}>
                  Em 5 anos, o método Kuadro ajudou milhares de estudantes a conquistarem
                </p>
                <p style={{ margin: 'auto', textAlign: 'center' }}>
                  <strong>mais de {countApprovals} aprovações nos vestibulares mais difíceis.</strong>
                </p>
              </div>
            </TextHeader>
          ) : (
            <TextHeader>
              <span>
                São <strong>mais de {countApprovals} aprovações em 5 anos </strong>nos vestibulares{' '}
                <strong>mais difíceis.</strong>
              </span>
            </TextHeader>
          )}
        </Holder>
      </Header>
      <Canvas>
        {step !== Step.CUSTOMER && <Title plan={plan} coupon={coupon} applyCoupon={applyCoupon} />}
        <PaymentContainer
          style={
            isPhoneDevice
              ? { marginTop: step !== Step.CUSTOMER ? 30 : 80 }
              : { margin: step !== Step.CUSTOMER ? '110px 400px 100px 0' : '110px auto 0' }
          }
        >
          <Steps step={step} />
          <div style={{ marginBottom: 30 }}>
            {step === Step.CUSTOMER && (
              <StudentCustomerCMP
                plan={plan}
                customer={customer}
                onChange={setCustomer}
                enableNext={validStudentCustomer}
                onCheckAdvisorEligible={checkAdvisorEligible}
                onNext={handleCustomerNext}
              />
            )}
            {step === Step.PAYMENT && (
              <div>
                <SaveButton onClick={handlePaymentBack}>Voltar</SaveButton>
                <FinancierCustomerCMP
                  plan={plan}
                  coupon={coupon}
                  customer={customer}
                  onChange={setCustomer}
                  checkedToU={checkedToU}
                  onCheckToU={setCheckedToU}
                  address={address}
                  enableNext={checkedToU && validCustomer && validAddress}
                  onChangeAddress={setAddress}
                  onNext={(): void => {}}
                />
                <PaymentCMP
                  plan={plan}
                  coupon={coupon}
                  payment={payment}
                  enableNext={enablePay}
                  enable
                  onChange={setPayment}
                  onNext={pay}
                />
              </div>
            )}
            {isAdvisor && <AdvisorDisclaimer />}
          </div>
          <HistoriesCMP />
        </PaymentContainer>
      </Canvas>
      <Footer>
        <Holder>
          <div style={{ width: '20%', justifyContent: 'center', display: 'flex' }}>
            <CDNImage src="logokuadro.svg" width="76px" />
          </div>
          <TextHeader>© 2022 Kuadro Ensino à Distância Ltda. · CNPJ 23.029.698/0001-73</TextHeader>
        </Holder>
      </Footer>
    </div>
  );
};

export default Checkout;
