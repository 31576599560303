import React from 'react';
import styled from 'styled-components';

import { KUADRO_GREEN } from '../constants/styles';
import { Step } from '../types';

const Title = styled.h2`
  color: ${KUADRO_GREEN};
  font-family: 'Roboto', sans-serif;
  font-size: 1.25rem;
  text-align: center;
`;

const FillCircle = styled.div`
  align-items: center;
  background: ${KUADRO_GREEN} 0% 0% no-repeat padding-box;
  border-radius: 14px;
  color: #ffffff;
  display: flex;
  font: normal normal bold 12px/16px Roboto;
  height: 28px;
  justify-content: center;
  width: 28px;
`;

const Circle = styled.div`
  align-items: center;
  border: 1px solid ${KUADRO_GREEN};
  border-radius: 14px;
  color: ${KUADRO_GREEN};
  display: flex;
  font: normal normal bold 12px/16px Roboto;
  height: 28px;
  justify-content: center;
  text-align: center;
  width: 28px;
`;

const Div = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const Spacer = styled.hr`
  color: #abff4f;
  height: 0px;
  margin: 0;
  width: 80px;
`;

const Steps: React.FC<{ step: Step }> = ({ step }) => {
  if (step !== Step.CUSTOMER && step !== Step.PAYMENT) return null;
  const stepCount = step === Step.CUSTOMER ? 0 : 1;
  const stepText = step === Step.CUSTOMER ? 'Cadastro do aluno' : 'Pagamento';
  return (
    <div>
      <Div>
        {[0, 1]
          .map(c =>
            c <= stepCount ? <FillCircle key={c + 1}>{c + 1}</FillCircle> : <Circle key={c + 1}>{c + 1}</Circle>
          )
          .map((el, i, arr) => [el, i < arr.length - 1 ? <Spacer key={i} /> : undefined])
          .flat()}
      </Div>
      <Title>{stepText}</Title>
    </div>
  );
};

export default Steps;
