import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import CheckoutCMP from '../components/Checkout';
import Loader from '../components/Loader';
import { getPlanAndCoupon, informGoogleAds, processPay } from '../api';
import { CheckoutSteps } from '../constants/app';
import { onPaymentTry, onPaymentConfirm, onPaymentSuccess, onPaymentFail } from '../utils/marketing';
import { Plan, Coupon, PaymentStatus, Customer, Address, PaymentInfo, PaymentMethod } from '../types';

interface PlanAndAfcProps {
  planCode: string;
  afcCode?: string;
  step?: string;
}

export interface CheckoutProps extends RouteComponentProps<PlanAndAfcProps> {
  forceBoletoAndCard?: boolean;
}

const Checkout: React.FC<CheckoutProps> = ({ history, match, forceBoletoAndCard }) => {
  const [plan, setPlan] = useState<Plan>();
  const [coupon, setCoupon] = useState<Coupon>();
  const [status, setStatus] = useState<PaymentStatus>(PaymentStatus.NO_PAYMENT);

  useEffect(() => {
    if (plan) return;

    const {
      params: { planCode, afcCode, step },
      path,
    } = match;
    const maxPathSize = path.split('/').filter(el => el).length + (step ? 0 : 1);
    const pathnames = window.location.pathname.split('/').filter(el => el);
    const pathStep = pathnames[pathnames.length - 1];
    if (!(pathStep in CheckoutSteps)) {
      history.push(
        '/' +
          [...(pathnames.length >= maxPathSize ? pathnames.slice(0, -1) : pathnames), CheckoutSteps.lead].join('/') +
          window.location.search
      );
      return;
    } else if (pathStep === CheckoutSteps.payment) {
      const params = new URLSearchParams(window.location.search);
      const hasValidPaymentData = params.get('p');
      if (!hasValidPaymentData) {
        history.push('/' + [...pathnames.slice(0, -1), CheckoutSteps.lead].join('/') + window.location.search);
        return;
      }
    }
    getPlanAndCoupon(planCode, afcCode).then(([p, c]) => {
      setCoupon(c);
      setPlan(p);
    });
  }, [match, history, plan]);

  if (!plan) return <Loader />;

  const applyCoupon = async (alias: string): Promise<boolean> => {
    if (!alias) {
      setCoupon(undefined);
      return false;
    }
    return getPlanAndCoupon(match.params.planCode, alias).then(resp => {
      setCoupon(resp[1]);
      return !!resp[1];
    });
  };

  const pay = (customer: Customer, address: Address, payment: PaymentInfo, origin: string): Promise<string> => {
    setStatus(PaymentStatus.NO_PAYMENT);
    switch (payment.method) {
      case PaymentMethod.PIX:
        onPaymentTry(customer, 'pix', plan);
        break;
      case PaymentMethod.BOLETO:
        onPaymentTry(customer, 'boleto', plan);
        break;
      case PaymentMethod.CARD:
        onPaymentTry(customer, 'credit-card', plan);
        break;
      case PaymentMethod.TWO_CARDS:
        onPaymentTry(customer, 'two-credit-cards', plan);
        break;
      case PaymentMethod.BOLETO_AND_CARD:
        onPaymentTry(customer, 'boleto-and-card', plan);
        break;
      default:
    }
    return processPay(plan, coupon, customer, address, payment, origin)
      .then(resp => {
        let paymentStatus = PaymentStatus.TWO_CARD_FAIL;
        informGoogleAds(payment, plan, coupon);
        if (plan.type === 'ADVISOR') {
          switch (payment.method) {
            case PaymentMethod.BOLETO:
              setStatus(PaymentStatus.ADVISOR_BOLETO_SUCCESS);
              return resp.url;
            case PaymentMethod.CARD:
              paymentStatus = resp.advisorAttributed
                ? PaymentStatus.ADVISOR_CARD_SUCCESS
                : PaymentStatus.ADVISOR_CARD_SUCCESS_NO_ADVISOR;
              onPaymentSuccess(customer, address, plan, coupon, paymentStatus);
              setStatus(paymentStatus);
              return '';
            case PaymentMethod.TWO_CARDS:
              if (resp.paymentSuccess && resp.advisorAttributed) paymentStatus = PaymentStatus.ADVISOR_TWO_CARD_SUCCESS;
              else if (resp.paymentSuccess && !resp.advisorAttributed)
                paymentStatus = PaymentStatus.ADVISOR_TWO_CARD_SUCCESS_NO_ADVISOR;
              else if (!resp.paymentSuccess && resp.advisorAttributed)
                paymentStatus = PaymentStatus.ADVISOR_TWO_CARD_SUCCESS_PAYMENT_FAIL;
              else paymentStatus = PaymentStatus.ADVISOR_TWO_CARD_SUCCESS_PAYMENT_FAIL_NO_ADVISOR;
              onPaymentSuccess(customer, address, plan, coupon, paymentStatus);
              setStatus(paymentStatus);
              return '';
            case PaymentMethod.BOLETO_AND_CARD:
              if (!resp.url) {
                setStatus(PaymentStatus.ADVISOR_BOLETO_AND_CARD_SUCCESS_NO_BOLETO);
                return '';
              }
              setStatus(
                resp.advisorAttributed
                  ? PaymentStatus.ADVISOR_BOLETO_AND_CARD_SUCCESS
                  : PaymentStatus.ADVISOR_BOLETO_AND_CARD_SUCCESS_NO_ADVISOR
              );
              return resp.url;
            default:
              return '';
          }
        }
        onPaymentConfirm(customer, address, plan);
        switch (payment.method) {
          case PaymentMethod.PIX:
            setStatus(PaymentStatus.PIX_SUCCESS);
            return resp.pixQRCode;
          case PaymentMethod.BOLETO:
            setStatus(PaymentStatus.BOLETO_SUCCESS);
            return resp.url;
          case PaymentMethod.CARD:
            paymentStatus = resp.studentCreated ? PaymentStatus.CARD_SUCCESS : PaymentStatus.CARD_SUCCESS_NO_STUDENT;
            onPaymentSuccess(customer, address, plan, coupon, paymentStatus);
            setStatus(paymentStatus);
            return '';
          case PaymentMethod.TWO_CARDS:
            if (resp.paymentSuccess && resp.studentCreated) {
              paymentStatus = PaymentStatus.TWO_CARD_SUCCESS;
            } else if (resp.paymentSuccess && !resp.studentCreated) {
              paymentStatus = PaymentStatus.TWO_CARD_SUCCESS_NO_STUDENT;
            } else if (!resp.paymentSuccess && resp.studentCreated) {
              paymentStatus = PaymentStatus.TWO_CARD_SUCCESS_PAYMENT_FAIL;
            } else {
              paymentStatus = PaymentStatus.TWO_CARD_SUCCESS_PAYMENT_FAIL_NO_STUDENT;
            }
            onPaymentSuccess(customer, address, plan, coupon, paymentStatus);
            setStatus(paymentStatus);
            return '';
          case PaymentMethod.BOLETO_AND_CARD:
            if (!resp.url) {
              paymentStatus = PaymentStatus.BOLETO_AND_CARD_SUCCESS_NO_BOLETO;
              onPaymentSuccess(customer, address, plan, coupon, paymentStatus);
            } else if (resp.studentCreated) {
              paymentStatus = PaymentStatus.BOLETO_AND_CARD_SUCCESS;
            } else {
              paymentStatus = PaymentStatus.BOLETO_AND_CARD_SUCCESS_NO_STUDENT;
              onPaymentSuccess(customer, address, plan, coupon, paymentStatus);
            }
            setStatus(paymentStatus);
            return resp.url;
          default:
            return '';
        }
      })
      .catch(() => {
        onPaymentFail(customer, address, payment.method, plan);
        switch (payment.method) {
          case PaymentMethod.PIX:
            setStatus(PaymentStatus.PIX_FAIL);
            return '';
          case PaymentMethod.BOLETO:
            setStatus(PaymentStatus.BOLETO_FAIL);
            return '';
          case PaymentMethod.CARD:
            setStatus(PaymentStatus.CARD_FAIL);
            return '';
          case PaymentMethod.TWO_CARDS:
            setStatus(PaymentStatus.TWO_CARD_FAIL);
            return '';
          case PaymentMethod.BOLETO_AND_CARD:
            setStatus(PaymentStatus.BOLETO_AND_CARD_FAIL);
            return '';
          default:
            return '';
        }
      });
  };

  return (
    <CheckoutCMP
      countApprovals={5000}
      plan={plan}
      coupon={coupon}
      forceBoletoAndCard={forceBoletoAndCard}
      status={status}
      onPay={pay}
      applyCoupon={applyCoupon}
    />
  );
};

export default Checkout;
