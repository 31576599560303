import React from 'react';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import { InputStyled } from './Input';

import { validateString, validateZipcode, validateNumber } from '../utils/validators';

import { Address } from '../types';
import pagarmeClient from '../pagarme';
import { STATES } from '../constants/app';

const useStyles = makeStyles(theme =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

const maskZipcode = (zipcode: string): string => {
  if (!zipcode) return '';
  if (zipcode.length < 6) return zipcode;
  return `${zipcode.substring(0, 5)}-${zipcode.substring(5, 8)}`;
};

const unmaskZipcode = (zipcode: string): string => {
  if (!zipcode) return '';
  return zipcode.replace(/[^0-9]/gi, '').substring(0, 8);
};

interface AddressProps {
  address: Address;
  onChange: (c: Address) => void;
}

const AddressCMP: React.FC<AddressProps> = ({ address, onChange }) => {
  const isPhoneDevice = useMediaQuery('(max-width: 480px)');

  const { zipcode, street, number, complement, neighborhood, city, state } = address;
  const maskedZipcode = maskZipcode(zipcode);
  const classes = useStyles();
  const gridHalfSpace = isPhoneDevice ? 12 : 6;

  const updateZipcode = (z: string): void => {
    onChange({ ...address, zipcode: z });
    if (validateZipcode(z)) {
      pagarmeClient()
        .then(client => client.zipcodes.find({ zipcode: z }))
        .then(resp => onChange({ ...address, ...resp, zipcode: z }))
        .catch(window.console.error);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <InputStyled
          type="text"
          inputMode="numeric"
          key="input_zipcode"
          value={maskedZipcode}
          onChange={(value): void => updateZipcode(unmaskZipcode(value))}
          title="CEP"
          validate={(value): boolean => validateZipcode(unmaskZipcode(value))}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <InputStyled
          type="text"
          inputMode="numeric"
          key="input_number"
          text="Número"
          value={number}
          onChange={(value): void => onChange({ ...address, number: value.replace(/[^0-9]/g, '') })}
          title="Número"
          validate={(value): boolean => validateNumber(value)}
          required
        />
      </Grid>
      <Grid item xs={gridHalfSpace}>
        <InputStyled
          key="input_street"
          text="Rua"
          value={street}
          onChange={(value): void => onChange({ ...address, street: value })}
          title="Rua"
          validate={(value): boolean => validateString(value)}
          required
        />
      </Grid>
      <Grid item xs={gridHalfSpace}>
        <InputStyled
          key="input_complement"
          text="Complemento"
          value={complement}
          onChange={(value): void => onChange({ ...address, complement: value })}
          title="Complemento"
        />
      </Grid>
      <Grid item xs={gridHalfSpace}>
        <InputStyled
          key="input_neighborhood"
          text="Bairro"
          value={neighborhood}
          onChange={(value): void => onChange({ ...address, neighborhood: value })}
          title="Bairro"
          validate={(value): boolean => validateString(value)}
          required
        />
      </Grid>
      <Grid item xs={gridHalfSpace}>
        <InputStyled
          key="input_city"
          text="Cidade"
          value={city}
          onChange={(value): void => onChange({ ...address, city: value })}
          title="Cidade"
          validate={(value): boolean => validateString(value)}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            label="Estado"
            select
            value={state}
            onChange={(e): void => onChange({ ...address, state: e.target.value as string })}
            required
          >
            {STATES.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default AddressCMP;
