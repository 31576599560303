import React, { useState } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';

import Card from '../components/Card';
import { Loader } from '../components/Button';
import End from '../components/End';

import { updateCard } from '../api';

import { validateString, validateCardNumber, validateCardCVV, validateCardExpirationDate } from '../utils/validators';

import { Plan, PaymentStatus, PaymentInfo, CardInfo, PaymentMethod } from '../types';

export const Canvas = styled.div`
  display: flex;
  background-color: white;
  box-sizing: border-box;
  flex-flow: row-reverse;
  margin-bottom: 80px;
  @media (max-width: 480px) {
    display: block;
  }
`;

export const Button = styled.button`
  width: fit-content;
  text-decoration: none;
  color: white;
  background: linear-gradient(to bottom, rgba(124, 208, 98, 1) 0%, #6fbb59 100%);
  padding: 10px 30px;
  display: inline-block;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.21);
  border-bottom: 4px solid rgba(0, 0, 0, 0.21);
  border-radius: 4px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  :active {
    background: #72b08e;
  }
  :disabled {
    cursor: default;
    background: rgb(221, 221, 221);
  }
  margin-right: 15px;
`;

const PaymentContainer = styled.div`
  padding: 5px;
  width: 50vw;
  @media (max-width: 480px) {
    min-width: 315px;
    margin-left: 30px;
  }
`;

interface TabProps {
  show: boolean;
}

const Tab = styled.div<TabProps>`
  ${(props): string => (!props.show ? 'display: none;' : '')}
  width: 100%;
`;

const validateCard = (card: CardInfo): boolean => {
  if (!validateString(card.name)) return false;
  if (Number.isNaN(card.installments)) return false;
  if (Number.isNaN(card.value)) return false;
  if (!validateCardExpirationDate(card.expirationDate)) return false;
  if (!validateCardNumber(card.number)) return false;
  if (!validateCardCVV(card.cvv)) return false;
  return true;
};

const TABS = {
  PAYMENT: 0,
  END: 1,
};

const FAKE_PLAN: Plan = {
  type: 'MONTHLY',
  classId: 0,
  count: 0,
  maxInstallments: 0,
  alias: '',
  name: '',
  price: 0,
  fullPrice: 0,
  imageUrl: '',
  enableDiscount: true,
  payByBoleto: true,
  payByPix: true,
  payByCard: true,
  payByTwoCards: true,
  until: '',
};

interface ChangeCardProps {
  enrollmentId: string;
}

const ChangeCard: React.FC<RouteComponentProps<ChangeCardProps>> = ({ match }) => {
  const [tab, setTab] = useState(TABS.PAYMENT);
  const [payment, setPayment] = useState<PaymentInfo>({
    method: PaymentMethod.CARD,
    cards: [
      {
        name: '',
        number: '',
        cvv: '',
        expirationDate: '',
        installments: 1,
        value: 0,
      },
    ],
  });
  const [paying, setPaying] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState<PaymentStatus>(PaymentStatus.NO_PAYMENT);

  const { enrollmentId } = match.params;
  const changeCard = async (): Promise<void> => {
    setPaying(true);
    const resp = await updateCard(enrollmentId, payment).catch(() => false);
    setPaymentSuccess(resp ? PaymentStatus.UPDATE_CARD_SUCCESS : PaymentStatus.UPDATE_CARD_FAIL);
    setTab(TABS.END);
    setPaying(false);
  };

  const validCard = validateCard(payment.cards[0]);

  return (
    <Canvas>
      <PaymentContainer>
        <Tab show={tab === TABS.PAYMENT}>
          <h2>{`Atualizar Cartão da Compra #${enrollmentId}`}</h2>
          <Card
            enableNext={validCard}
            payment={payment}
            plan={FAKE_PLAN}
            paying={paying}
            onChangeCard={(c): void => setPayment({ ...payment, cards: [c] })}
          />
          <div style={{ marginTop: 16, textAlign: 'right' }}>
            <Button disabled={!validCard || paying} onClick={changeCard}>
              {paying ? <Loader /> : 'Atualizar Cartão'}
            </Button>
          </div>
        </Tab>
        <Tab show={tab === TABS.END}>
          <End status={paymentSuccess} boletoUrl="" email="" onRetry={(): void => setTab(TABS.PAYMENT)} />
        </Tab>
      </PaymentContainer>
    </Canvas>
  );
};

export default ChangeCard;
